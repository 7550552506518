import React, { Component } from "react";
// import { linkInit, API_URL } from "../../constants/config";
import { API_URL } from "../../constants/config";
import axios from "axios";
// import LOGO from "../../assets/img/pelz_golf_logo.gif";
// import PROFILE from "../../assets/img/profile.png";
import APPLE_STORE from "../../assets/img/apple_store.jpg";
import GOOGLE_PLAY from "../../assets/img/google-play.jpg";
// import TOGGLE from "../../assets/img/toggle-icon.png";
// import BACK_BUTTON from "../../assets/img/back-icon.png";
import MessageBox from "./message_box";

class GetApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestEmail: "",
      errorMessage: "",
      modal: false,
      successMessage: false
    };
    this.toggle = this.toggle.bind(this);
  }

  /**
   * @todo This method will  set state for modal
   * @return {null}
   */

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  /**
   * @todo This method will handle all inputchanges and set state
   * @param {object} e
   * @return {null}
   */
  handleChnage = event => {
    let value = event.target.value;
    this.setState({
      ...this.state,
      requestEmail: value,
      successMessage: false
    });
  };

  /**
   * @todo This method will handle form submission and set state
   * @param {object} event
   * @return {null}
   */
  handleSubmit = event => {
    event.preventDefault();
    let user_email = this.state.requestEmail;
    if (user_email === "") {
      this.setState({
        ...this.state,
        errorMessage: "Please enter the email address"
      });
    } else {
      this.setState({
        ...this.state,
        errorMessage: ""
      });
      // this.state.errorMessage = "";
      axios
        .post(`${API_URL}/public/requestEmail`, { requestEmail: user_email })
        .then(result => {
          //console.log(result.data.status, "result")
          if (result.data.status) {
            this.setState({
              ...this,
              successMessage: true
            });
          }
        })
        .catch(err => {
          console.log(err, "err");
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="main-container">
          <div className="feature">
            <div className="container">
              <h1 className={"app-text-heading"}>
                Imaginine Dave Pelz and our instructors Coaching YOU at home,
                your office, backyard, and practice facility:
              </h1>
              <ul>
                <li>Green Reading Fundamentals</li>
                <li>Advanced Green Reading</li>
                <li>Wedge 3x4 System</li>
                <li>and More?</li>
              </ul>
            </div>
          </div>

          <div className="green-reading">
            <div className="container">
              <div className="row">
                <div className="read-putt">
                  <div className="col-xs-12">
                    <p>
                      Would you like to be one of the 1st to try our new
                      interactive Learning Experiences?
                    </p>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          Enter your email below:
                        </label>
                        {this.state.errorMessage !== "" && (
                          <p>{this.state.errorMessage}</p>
                        )}
                        <input
                          name="requestEmail"
                          value={this.state.requestEmail}
                          onChange={this.handleChnage}
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your email"
                          required
                        />
                        <small
                          id="emailHelp"
                          className="form-text text-muted"
                        />
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        style={{ fontWeight: "600" }}
                      >
                        YES
                      </button>
                    </form>
                    {this.state.successMessage && (
                      <div className="successMessage">
                        <p>THANK YOU!</p>
                        <p>
                          We can't wait to get our new Pelz PlayerTM teaching
                          experiences to Golfers like you.
                        </p>
                        <p>
                          In the meantime, join us at a Dave Pelz Scoring Game
                          School or Clinic near you!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="download-section">
            <div className="container">
              <p className="coming-soon">Coming Soon</p>
              <h5 className="download-text">Download Via</h5>
              <img src={APPLE_STORE} alt="" />
              <img src={GOOGLE_PLAY} alt="" />
            </div>
          </div>
        </div>
        {/* <button onClick={this.toggle}>Click Here</button> */}
        {this.state.modal && (
          <MessageBox isOpen={this.state.modal} toggle={this.toggle} />
        )}
      </React.Fragment>
    );
  }
}

export default GetApp;
