import React, {Fragment}from "react";
import {  Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
// import App from "./App";
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import HomeContainer from "./containers/home_container";
import GreenReadingContainer from "./containers/green_container";
import Setting from "./components/green_reading/settings";
import GetApp from "./components/green_reading/get_app";
import Graph from "./components/green_reading/graph";
import Login from './components/auth/login';
import NotFound from './components/not_found';
import Register from  './components/auth/register'

const history = createBrowserHistory();
const ReactRouter = ({location}) => {
    return (
        <Fragment>
        <Header location={location} history={history}/>
            <Switch>
                <Route location={location} exact path="/" component={HomeContainer} />
                <Route location={location} exact path="/1" component={HomeContainer} />
                <Route location={location}  path="/green-reading" component={GreenReadingContainer} />
                <Route location={location}  path="/setting" component={Setting} />
                <Route location={location}  path="/get-app" component={GetApp} />
                <Route location={location}  path="/graph" component={Graph} />
                <Route location={location}  path="/login" component={Login} />
                <Route location={location}  path="/register/:hash" component={Register} />
                <Route location={location} path={'*'} component={NotFound} />
            </Switch>
        <Footer location={location} />
        </Fragment>
    );
}

export default ReactRouter;