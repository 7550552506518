import React, { Component } from 'react';
import Common from '../../components/common';




class Home extends Component {


  componentDidMount(){
   
  }

  render() {
    return (
        <Common location={this.props.location} />
    );
  }
}

export default Home;
