import React, { Component } from "react";
import ReactBootstrapSlider from "react-bootstrap-slider";
import "bootstrap-slider/dist/css/bootstrap-slider.css";

import { API_URL } from "../../constants/config";
import axios from "axios";
import * as constant from "../../constants/config";
import SHOOZE2 from "../../assets/img/left.png";
import SHOOZE from "../../assets/img/right.png";
import ARROW1 from "../../assets/img/right-arrow.png";
import ARROW2 from "../../assets/img/right-arrow-2.png";
import ARROWLEFT1 from "../../assets/img/left-arrow.png";
import WHITEBALL from "../../assets/img/icons/white-ball.png";
import YELLOWBALL from "../../assets/img/icons/yellow-ball.png";
import GRASSTEXTURE from "../../assets/img/icons/grass-texture.png";
import HOLE from "../../assets/img/icons/hole.png";
import button1X from "../../assets/img/icons/1x.png";
import button2X from "../../assets/img/icons/2x.png";
import button4X from "../../assets/img/icons/4x.png";
import AIMPOINT from "../../assets/img/icons/aim-icon.png";
import PUTTLENGTHTRIANGLE from "../../assets/img/icons/corner-puttlength.png";
import PUTTTIMETRIANGLE from "../../assets/img/icons/corner-putt-time.png";
import LARGESLOPEARROW from "../../assets/img/icons/arrow-large.png";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import ROTATE from '../../assets/img/Rotate.png';
import MediaQuery from 'react-responsive'



class Graph extends Component {
  timer;
  ballTimer;
  constructor(props) {
    super(props);

    let calculatorValue = JSON.parse(localStorage.getItem("formData"));
    let copyParam = {
      GreenSpeed: 10,
      PASlope: 2,
      PLength: 6,
      PuttTime: "9:30"
    };
    if (calculatorValue != null) {
      copyParam = {
        GreenSpeed: calculatorValue.GreenSpeed,
        PASlope: calculatorValue.PASlope,
        PLength: calculatorValue.PLength,
        PuttTime: calculatorValue.PuttTime
      };
    }
    let currentPuttTime = copyParam.PuttTime.split(":");
    let setPuttTime =
      parseInt(currentPuttTime[0]) + parseFloat(currentPuttTime[1] / 60);

    this.ballTimer = null;
    this.timer = null;
    this.animateButtonPosition = true
    this.playButtonPositon = '-100px';

    this.state = {
      stopAnimation: true,
      puttBreakNew: 0,
      puttPowerNew: 0,
      showPelzLine: true,
      PelzLinePosition: '-100px',
      playButtonPositon: '-100px',
      showSliders: false,
      animateSpeed: 1,
      playerPlayingStyle: "Righty",
      tips: "Off",
      PelzLine: "Off",
      PuttPowerSwitch: "Off",
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      graphHeight: parseInt((window.screen.height * 75) / 100),
      sliderHeight: parseInt((window.screen.height * 25) / 100),
      formData: copyParam,
      Output: {
        PuttPower: 0,
        Break: 0
      },
      PuttLengthPixel: 0,
      aimXAxis: 0,
      aimYAxis: 0,
      deaultValue: [],
      showStatus: true,
      PuttTime: {
        currentValue: setPuttTime,
        max: 12, // 12.75
        min: 0, //1  // mahendra updated // 0.25
        step: 0.25
      },
      PLength: {
        currentValue: copyParam.PLength,
        max: 12,
        min: 2,
        step: 0.5
      },
      PASlope: {
        currentValue: copyParam.PASlope,
        max: 4.5,
        min: 0,
        step: 0.5
      },
      GreenSpeed: {
        currentValue: copyParam.GreenSpeed,
        max: 15,
        min: 7,
        step: 0.5
      },
      timerTime: 1,
      animateButtonText: "Putt"
    };
  }

  /**
   * @todo This method will handle all sliders inputchanges , set state and call another method
   * @param {object} e
   * @param {string} sliderName
   * @return {null}
   */

  changeSliderValue = (e, sliderName) => {
    this.setState({
      [sliderName]: {
        ...this.state[sliderName],
        currentValue: e.target.value
      }
    });
    this.handleRequest(e, sliderName);
  };

  /**
   * @todo This method will handle incremental sliders inputchanges , set state and call another method
   * @param {string} sliderName
   * @return {null}
   */

  increaseSliderValue = sliderName => {
    if (
      this.state[sliderName].currentValue + this.state[sliderName].step >
      this.state[sliderName].max
    )
      return;
    this.setState(
      {
        [sliderName]: {
          ...this.state[sliderName],
          currentValue:
            this.state[sliderName].currentValue + this.state[sliderName].step
        }
      },
      () =>
        this.handleRequest(
          null,
          sliderName,
          this.state[sliderName].currentValue
        )
    );
  };

  /**
   * @todo This method will handle decreamental sliders inputchanges , set state and call another method
   * @param {string} sliderName
   * @return {null}
   */

  decreaseSliderValue = sliderName => {

    console.log(sliderName, "sliders", this.state[sliderName].currentValue - this.state[sliderName].step)
    if (
      this.state[sliderName].currentValue - this.state[sliderName].step <
      this.state[sliderName].min
    )
      return;
    this.setState(
      {
        [sliderName]: {
          ...this.state[sliderName],
          currentValue:
            this.state[sliderName].currentValue - this.state[sliderName].step
        }
      },
      () =>
        this.handleRequest(
          null,
          sliderName,
          this.state[sliderName].currentValue
        )
    );
  };

  /**
   * @todo This method will calculate Putt Time
   * @param {string} val
   * @return {string}
   */

  calculatePuttTime = val => {
    let calcHour = Math.floor(val);
    const calcMinute = (((val - calcHour) * 3) / 5).toString().substring(2);
    console.log('calcHour', calcHour, 'calcMinute', calcMinute)
    if (calcMinute.length > 1) {
      if (calcHour == 0)
        calcHour = 12;
      return calcHour + ":" + calcMinute;
    }
    if (calcMinute.length > 0) {
      return calcHour + ":" + calcMinute + "0";
    }
    return calcHour + ":" + calcMinute + "00";
  };

  /**
   * @todo This method will convert to feet and inches
   * @param {string} val
   * @return {string}
   */

  calculateInFeetnInch = val => {
    const calcFeet = Math.floor(val);
    const calcInch = (val - calcFeet).toString().substring(2);

    if (calcInch.length === 0) {
      return calcFeet + "'";
    }

    return calcFeet + "'" + calcInch + '"';
  };

  /**
   * @todo This method will get default value to graph to local storage
   * @param {string} name
   * @param {string} value
   * @return {undefined}
   */
  setDefaultValueToGraph = (name, value) => {
    let setLocalValue = {
      GreenSpeed: this.state.formData.GreenSpeed,
      PASlope: this.state.formData.PASlope,
      PLength: this.state.formData.PLength,
      PuttTime: this.state.formData.PuttTime
    };
    console.log('setting value here', name, value)
    setLocalValue[name] = value;
    localStorage.setItem("formData", JSON.stringify(setLocalValue));
  };

  /**
   * @todo This method will get default value to graph to local storage
   * @param {string} event
   * @param {string} action
   * @param {string} currentValue
   * @return {undefined}
   */
  handleRequest = (event, action, currentValue) => {
    let value = "";

    if (currentValue == 0 && action !== 'PASlope') {
      currentValue = 12
    }

    if (currentValue === 0 || currentValue > 0) {
      if (action != "PuttTime") {
        value = parseFloat(currentValue);
      } else {
        value = this.calculatePuttTime(currentValue);
      }
    } else {
      if (action != "PuttTime") {
        value = parseFloat(event.target.value);
      } else {
        value = this.calculatePuttTime(event.target.value == 0 ? 12 : event.target.value);
        // value = event.target.value == 0 ? this.calculatePuttTime(12) : this.calculatePuttTime(event.target.value);
      }
    }

    this.setDefaultValueToGraph(action, value);
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [action]: value
      },
      showStatus: true
    });
  };


  /**
   * @todo This method will help in getting the Greading solutions from the database to be used in application 
   * @return {null}
   */

  getDefaultValue = () => {
    axios
      .get(`${API_URL}/public/solution`)
      .then(response => {
        this.setState({
          ...this.state,
          deaultValue: response.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillMount() {
    let maxPuttLength = 320;
    let inchToPixel = (maxPuttLength / 144).toFixed(2);
    let lengthInPixel = Math.round(
      this.state.formData.PLength * 12 * inchToPixel
    );
    this.setState({
      ...this.state,
      PuttLengthPixel: lengthInPixel
    });
  }

  componentWillUnmount() {
    if (document.querySelector('#mobile-header')) {

      document.querySelector('#mobile-header').style.display = 'block';
    }
  }

  componentDidMount() {

    this.getDefaultValue();

    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight
    });

    let token = null;
    if (
      localStorage.getItem("auth") !== "null" &&
      localStorage.getItem("auth") !== null
    ) {
      token = JSON.parse(localStorage.getItem("auth")).token;
    }
    axios
      .get(constant.API_URL + "/private/playerSettings", {
        headers: {
          Authorization: "Bearer " + token //the token is a variable which holds the token
        }
      })
      .then(result => {
        this.setState({
          tips: result.data[0].Tips,
          PuttPowerSwitch: result.data[0].PuttPower,
          playerPlayingStyle: result.data[0].playerPlayingStyle,
          PelzLine: result.data[0].PelzLine
        });
      })
      .catch(err => {
        console.log(err, "err");
      });
  }

  componentDidUpdate(prevProps, prevState) {
    // let type = window.screen.orientation.type;
    var mql = window.matchMedia("(orientation: portrait)");
    if (this.state.screenWidth > 870)
      this.calculateDynamicCoordinates();

    if (mql.matches && !this.state.orientationchanged) {
      this.calculateDynamicCoordinates();
    }
  }
  handleMediaQueryChange = (matches) => {
    if ((matches)) {
      this.calculateDynamicCoordinates();
    }

  }

  /**
   * @todo This method will handle processing of the Greading solutions 
   * @param {object} roundDown
   * @param {object} roundUp
   * @param {object} weightingTable
   * @return {Array} 
   */


  getGReadingSolution = async (roundDown, roundUp, weightingTable) => {
    let solutionArr = [];
    for (let i = 1; i <= 16; i++) {
      let gSpeed = 0;
      let pSlope = 0;
      let pLength = 0;
      let pTime = 0;
      let degree = 0;
      if (i <= 4 || (i >= 9 && i <= 12)) {
        gSpeed = roundDown.GreenSpeed;
      } else if ((i > 4 && i <= 8) || (i >= 13 && i <= 16)) {
        gSpeed = roundUp.GreenSpeed;
      }

      if (
        i <= 2 ||
        (i > 4 && i <= 6) ||
        (i > 8 && i <= 10) ||
        (i > 12 && i <= 14)
      ) {
        pSlope = roundDown.PASlope;
      } else if (
        (i > 2 && i <= 4) ||
        (i > 6 && i <= 8) ||
        (i > 10 && i <= 12) ||
        (i > 14 && i <= 16)
      ) {
        pSlope = roundUp.PASlope;
      }

      if (i % 2 == 0) {
        pLength = roundUp.PLength;
      } else {
        pLength = roundDown.PLength;
      }

      if (i <= 8) {
        pTime = roundDown.PuttTime;
      } else if (i > 8) {
        pTime = roundUp.PuttTime;
      }

      //=180-(12-HOUR(Q36))*30
      if (pTime != "") {
        let getTime = pTime.split(":");
        degree = 180 - (12 - getTime[0]) * 30;
      }
      let solution = {
        GreenSpeed: gSpeed,
        PASlope: pSlope,
        PLength: pLength,
        PuttTime: pTime,
        degree: degree
      };

      let rawData = this.getFilteredSolution(solution);
      let currentElement = {};
      if (rawData != undefined) {
        currentElement = {
          solution: solution,
          raw_data: {
            PuttPower: parseFloat(rawData.PuttPower),
            Break: parseFloat(rawData.Break)
          },
          weighted: {
            PuttPower: (rawData.PuttPower * weightingTable[i - 1].weight) / 100,
            Break: (rawData.Break * weightingTable[i - 1].weight) / 100
          }
        };
        solutionArr.push(currentElement);
      }
    }
    return solutionArr;
  };

  /**
   * @todo This method will handle filtration of the solutions 
   * @param {object} solution
   * @return {object} 
   */

  getFilteredSolution = solution => {
    let status = { PuttPower: 0, Break: 0.0 };
    let totalSolution = this.state.deaultValue;
    totalSolution.forEach((item, key) => {
      if (
        item.greenSpeed == solution.GreenSpeed &&
        item.pASlope == solution.PASlope &&
        item.puttLength == solution.PLength &&
        item.puttTime == solution.PuttTime &&
        item.puttAngle == solution.degree
      ) {
        status = { PuttPower: item.puttPower, Break: item.break };
        return false;
      }
    });
    return status;
  };

  /**
   * @todo This method will handle processing of the weighting solutions 
   * @param {Array} weightingDown
   * @param {Array} weightingUp
   * @return {Array} 
   */

  getWeightingTable = (weightingDown, weightingUp) => {
    let weight_table = [
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingDown.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingDown.PuttTime / 100) *
          100
      },
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      },
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      },
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      },
      {
        d: weightingDown.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingDown.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingDown.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingDown.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingDown.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingDown.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      },
      {
        d: weightingUp.GreenSpeed,
        PASlope: weightingUp.PASlope,
        th: weightingUp.PLength,
        PuttTime: weightingUp.PuttTime,
        weight:
          (weightingUp.GreenSpeed / 100) *
          (weightingUp.PASlope / 100) *
          (weightingUp.PLength / 100) *
          (weightingUp.PuttTime / 100) *
          100
      }
    ];
    return weight_table;
  };

  /**
   * @todo This method will handle processing of the RoundDown  
   * @param {Array} data
   * @return {Array} 
   */

  getRoundDown = async data => {
    let greenSpeedDown = Math.floor(data.GreenSpeed);
    let slopeDown = Math.floor(data.PASlope);
    let lengthDown = 0;
    //=IF(P30<3, 2, 3*ROUNDDOWN(P30/3,0))
    if (data.PLength < 3) {
      lengthDown = 2;
    } else {
      lengthDown = 3 * Math.floor(data.PLength / 3);
    }

    //=TIME(HOUR(Q30),0,0)
    let timeDown = "";
    let timeDownHour = 0;
    if (data.PuttTime != "") {
      timeDown = data.PuttTime.split(":");
      if (
        parseInt(timeDown[0]) < 6 ||
        (parseInt(timeDown[0]) == 12 && parseInt(timeDown[0]) > 0)
      ) {
        let mirrorTime = await this.clockInverse(timeDown);
        timeDownHour = mirrorTime[0] + ":00";
      } else {
        timeDownHour = timeDown[0] + ":00";
      }
    }

    let downVal = {
      GreenSpeed: greenSpeedDown,
      PASlope: slopeDown,
      PLength: lengthDown,
      PuttTime: timeDownHour
    };
    return downVal;
  };

  /**
   * @todo This method will handle processing of the RoundUp  
   * @param {Array} data
   * @return {Array} 
   */

  getRoundUp = data => {
    let greenSpeedUp = Math.ceil(data.GreenSpeed);
    let slopeUp = 0;
    //=IF(O30<=4,ROUNDUP(O30,0), 4.5)
    if (data.PASlope <= 4) {
      slopeUp = Math.ceil(data.PASlope);
    } else {
      slopeUp = 4.5;
    }

    let lengthUp = 0;
    let timeUpHour = 0;
    //=IF(P30<3, 3, 3*ROUNDUP(P30/3,0))
    if (data.PLength < 3) {
      lengthUp = 3;
    } else {
      lengthUp = 3 * Math.ceil(data.PLength / 3);
    }

    //=IF(Q30=TIME(12,0,0), TIME(12,0,0), TIME(HOUR(Q30)+1,0,0))
    let dataPuttTime = data.PuttTime;
    if (dataPuttTime != "") {
      let timeSplit = dataPuttTime.split(":");
      if (
        parseInt(timeSplit[0]) < 6 ||
        (parseInt(timeSplit[0]) == 12 && parseInt(timeSplit[0]) > 0)
      ) {
        timeSplit = this.clockInverse(timeSplit);
      }
      if (dataPuttTime == timeSplit[0]) {
        timeUpHour = timeSplit[0];
      } else {
        timeUpHour = (parseInt(timeSplit[0]) + 1).toString() + ":00";
      }
    }

    let upVal = {
      GreenSpeed: greenSpeedUp,
      PASlope: slopeUp,
      PLength: lengthUp,
      PuttTime: timeUpHour
    };
    return upVal;
  };

  /**
   * @todo This method will handle clock Inverse logic  
   * @param {Array} hours
   * @return {Array} 
   */

  clockInverse = hours => {
    // let hr = 12 - hours[0]; before

    let hr = hours[0] == 12 ? 12 : 12 - hours[0];
    let mint = hours[1];
    if (mint == 0) {
      hours[0] = hr;
    } else {
      hours[0] = hr - 1;
      hours[1] = 60 - hours[1];
    }
    return hours;
  };

  /**
   * @todo This method will handle processing of the Weighting Down 
   * @param {Array} roundDown
   * @param {Array} roundUp
   * @return {Array} 
   */

  getWeightingDown = (roundDown, roundUp) => {
    let data = this.state.formData;
    let weightGreenSpeedDown = 0;
    //=IF(N30=N31, 100%, (N32-N30)/(N32-N31))
    if (data.GreenSpeed == roundDown.GreenSpeed) {
      weightGreenSpeedDown = 100;
    } else {
      weightGreenSpeedDown = (
        ((roundUp.GreenSpeed - data.GreenSpeed) /
          (roundUp.GreenSpeed - roundDown.GreenSpeed)) *
        100
      ).toFixed(1);
    }

    let weightSlopeDown = 0;
    //=IF(O30=O31, 100%, (O32-O30)/(O32-O31))
    if (data.PASlope == roundDown.PASlope) {
      weightSlopeDown = 100;
    } else {
      weightSlopeDown = (
        ((roundUp.PASlope - data.PASlope) /
          (roundUp.PASlope - roundDown.PASlope)) *
        100
      ).toFixed(1);
    }

    let weightLengthDown = 0;
    //=IF(P30=P31, 100%, (P32-P30)/(P32-P31))
    if (data.PLength == roundDown.PLength) {
      weightLengthDown = 100;
    } else {
      weightLengthDown = (
        ((roundUp.PLength - data.PLength) /
          (roundUp.PLength - roundDown.PLength)) *
        100
      ).toFixed(1);
    }

    let weightTimehDown = 0;
    //=IF(Q30=Q31, 100%, (Q32-Q30)/(Q32-Q31))

    let dataPuttTime = data.PuttTime;
    let timeSplit = dataPuttTime.split(":");
    if (
      parseInt(timeSplit[0]) < 6 ||
      (parseInt(timeSplit[0]) == 12 && parseInt(timeSplit[0]) > 0)
    ) {
      let mirrorWeightingDown = this.clockInverse(timeSplit);
      dataPuttTime = mirrorWeightingDown[0] + ":" + mirrorWeightingDown[1];
    }
    let firstTime = this.getSubtractionTime(roundUp.PuttTime, dataPuttTime);
    let secondTime = this.getSubtractionTime(
      roundUp.PuttTime,
      roundDown.PuttTime
    );

    if (dataPuttTime == roundDown.PuttTime) {
      weightTimehDown = 100;
    } else {
      weightTimehDown = (firstTime / secondTime) * 100;
    }

    let weightDown = {
      GreenSpeed: weightGreenSpeedDown,
      PASlope: weightSlopeDown,
      PLength: parseFloat(weightLengthDown),
      PuttTime: weightTimehDown
    };
    return weightDown;
  };

  /**
   * @todo This method will handle processing of the Putt Time substraction
   * @param {String} startTime
   * @param {String} endTime
   * @return {String} 
   */

  getSubtractionTime = (startTime, endTime) => {
    let sTime = startTime.split(":");
    let eTime = endTime.split(":");
    let fMinutes = 0;
    let fHours = 0;
    if (sTime[1] > eTime[1] && sTime[0] > eTime[0]) {
      fMinutes = parseInt(sTime[1]) - parseInt(eTime[1]);
      fHours = parseInt(sTime[0]) - parseInt(eTime[0]);
    } else {
      fMinutes = parseInt(sTime[1]) + 60 - parseInt(eTime[1]);
      fHours = parseInt(sTime[0]) - 1 - parseInt(eTime[0]);
    }
    return fMinutes;
  };

  /**
   * @todo This method will handle processing of the WeightingUp
   * @param {Object} weightingDown
   * @return {Object} 
   */

  getWeightingUp = weightingDown => {
    let data = this.state.formData;
    //=1-N33
    let weightGreenSpeedUp = 100 - weightingDown.GreenSpeed;
    //=1-O33
    let weightSlopeUp = 100 - weightingDown.PASlope;
    //=1-P33
    let weightLengthUp = 100 - weightingDown.PLength;
    //=1-Q33
    let weightTimehUp = 100 - weightingDown.PuttTime;

    let weightUp = {
      GreenSpeed: weightGreenSpeedUp,
      PASlope: weightSlopeUp,
      PLength: parseFloat(weightLengthUp),
      PuttTime: weightTimehUp
    };
    return weightUp;
  };

  /**
   * @todo This method will handle processing of the calculation of the values
   * @return {null} 
   */

  getCalculateValue = async () => {
    let roundDown = await this.getRoundDown(this.state.formData);
    let roundUp = await this.getRoundUp(this.state.formData);
    let weightingDown = await this.getWeightingDown(roundDown, roundUp);
    let weightingUp = await this.getWeightingUp(weightingDown);
    let weightingTable = await this.getWeightingTable(
      weightingDown,
      weightingUp
    );

    let solution = await this.getGReadingSolution(
      roundDown,
      roundUp,
      weightingTable
    );
    if (this.state.showStatus) {
      let totalPuttPoer = 0;
      let totalBreak = 0;
      for (let i = 0; i < solution.length; i++) {
        totalPuttPoer =
          totalPuttPoer + parseFloat(solution[i].weighted.PuttPower.toFixed(3));
        totalBreak =
          totalBreak + parseFloat(solution[i].weighted.Break.toFixed(3));
      }

      //=IF(INT(V68/12)>0, INT(V68/12) & " ft " & ROUND(MOD(V68,12),1) & " in", ROUND(MOD(V68,12),1) & " in")
      let calBreak = String(totalBreak % 12);
      if (totalBreak > 12) {
        //totalBreak = parseInt(totalBreak / 12) + ' ft ' + (totalBreak % 12).toFixed(1) + ' in';
        let totalBreakFt = parseInt(totalBreak / 12);
        let totalBreakInch = (totalBreak % 12).toFixed(1);
        totalBreak = {
          totalBreakFt: totalBreakFt,
          totalBreakInch: totalBreakInch
        };
      } else {
        let totalBreakInch = (totalBreak % 12).toFixed(1);
        totalBreak = { totalBreakFt: "", totalBreakInch: totalBreakInch };
      }

      this.setState({
        ...this.state,
        Output: {
          //=10*((U68-2.5-0.84)/10.54)
          PuttPower: (10 * ((totalPuttPoer - 2.5 - 0.84) / 10.54)).toFixed(1),
          Break: totalBreak
        }
        // showStatus: false
      });

      await this.calculateAxis(totalBreak);
    }
  };


  /**
   * @todo This method will handle calculation of the Axis
   * @param {object} totalBreak
   * @return {null} 
   */

  calculateAxis = totalBreak => {
    let puttBreak = 0;
    if (totalBreak.totalBreakFt != "") {
      puttBreak = totalBreak.totalBreakFt * 12;
    }
    puttBreak += parseFloat(totalBreak.totalBreakInch);
    let lengthInInch = this.state.formData.PLength * 12;

    //Aim Point Calculations
    let alpha = Math.asin(puttBreak / 2 / lengthInInch);

    let aimX = (puttBreak * Math.cos(alpha)).toFixed(2);
    let aimY = (puttBreak * Math.sin(alpha)).toFixed(2);
    this.setState({
      ...this.state,
      PuttLengthPixel: lengthInInch,
      showStatus: false,
      puttBreak: puttBreak
    });
  };


  /**
   * @todo This method will handle mirrored data based on the Putt time
   * @return {object} 
   */

  getClockMirrorData = () => {
    let currentTime = this.state.formData.PuttTime.split(":");
    let timeHours = parseInt(currentTime[0]);
    let timeMinutes = parseInt(currentTime[1]);
    let clockMirror = { positionText: "", hillText: "" };

    if (timeHours === 12 || (timeHours >= 1 && timeHours < 6)) {
      clockMirror.positionText = "Right to Left";
    } else {
      clockMirror.positionText = "Left to Right";
    }

    if (
      (timeHours >= 10 && timeHours <= 12) ||
      (timeHours === 1 || (timeHours === 2 && timeMinutes === 0))
    ) {
      clockMirror.hillText = "Downhill";
    } else if (
      (timeHours >= 4 && timeHours <= 7) ||
      (timeHours === 8 && timeMinutes === 0)
    ) {
      clockMirror.hillText = "Uphill";
    } else if (
      (timeHours === 2 && timeMinutes >= 15) ||
      (timeHours === 3 && timeMinutes <= 45) ||
      (timeHours === 8 && timeMinutes >= 15) ||
      timeHours < 10
    ) {
      clockMirror.hillText = "Sidehill";
    }
    return clockMirror;
  };



  /**
   * @todo This method will reformat the putt time
   * @param {string} t
   * @return {string} 
   */

  puttTimeReformater = (t) => {
    if (t.split(":")[0] == '0') {
      t = "12:" + t.split(":")[1]
    }
    return t
  }


  /**
   * @todo This method handles the Animate button states
   * @return {object} 
   */

  processAimateButtonUI = () => {
    let imgSource;

    switch (this.state.animateButtonText) {
      case "1x":
        imgSource = button1X;
        break;
      case "2x":
        imgSource = button2X;
        break;
      default:
        imgSource = button4X;
    }

    return (
      <img className="animate-button-img" src={imgSource} alt="animateButton" />
    );
  };


  /**
   * @todo This method handles the Animate button states
   * @return {object} 
   */

  processAimateButtonUI2 = () => {
    let imgSource;

    switch (this.state.animateButtonText) {
      case "1x":
        imgSource = button2X;
        break;
      case "2x":
        imgSource = button4X;
        break;
      default:
        imgSource = button4X;
    }

    return (
      <img className="animate-button-img" src={imgSource} alt="animateButton" />
    );
  };


  /**
   * @todo his method will calculate the Graphs and Will draw the graph using the Canvas that get data from the calculations
   * @return {null} 
   */

  calculateDynamicCoordinates = () => {
    let database = [];
    let mirror;
    let topInches = 25;
    let bottomInches = 12;

    // step 2
    let puttTime = this.state.formData.PuttTime;
    let puttLength = this.state.formData.PLength;
    let pinAreaSlope = this.state.formData.PASlope;
    let GreenSpeed = this.state.formData.GreenSpeed;
    let PinAreaSlopePercent = pinAreaSlope / 100;

    let pTime = parseInt(this.state.formData.PuttTime.split(":").join(""), 10);

    mirror = pTime >= 600 && pTime < 1200 ? true : false;

    let puttBreak;

    if (this.state.Output.Break.totalBreakFt) {
      puttBreak =
        this.state.Output.Break.totalBreakFt * 12 +
        parseFloat(this.state.Output.Break.totalBreakInch);
    } else {
      puttBreak = this.state.Output.Break.totalBreakInch;
    }

    let puttPower = this.state.Output.PuttPower; //always in feet

    // step 3
    let puttTimeDegrees = this.getDegreeAndRadiansByTime(puttTime);
    let puttTimeRadians = parseFloat(
      (puttTimeDegrees * (Math.PI / 180)).toFixed(9)
    );

    // step 4
    let PinAreaSlopeRadians = parseFloat(
      Math.atan(PinAreaSlopePercent).toFixed(9)
    );

    // step 5
    let vInitialFtSec = (puttPower * 3.92850895238095) ** 0.5;

    // step 6
    let puttAimAngleRadians =
      puttTimeRadians - 2 * Math.asin(puttBreak / 2 / (puttLength * 12));
    // step 7

    let negGSInTheta = -32 * Math.sin(PinAreaSlopeRadians);
    // step 8
    let negMuRFGcostheta =
      ((-32 * 0.836599233801047) / GreenSpeed) *
      Math.cos(Math.atan(pinAreaSlope / 100));

    // step 9
    let x;
    let y;
    let xP;
    let yP;
    let xDelta;
    let yDelta;

    // step 10
    let t = 0;
    let tFinal;

    // step 11
    let vX;
    let vXPrior;
    let vY;
    let vYPrior;
    let v;
    let vPrior;
    let aX;
    let aXprior;
    let aY;
    let aYPrior;
    let phiRadians;
    let xR;
    let yR;

    // step  12
    t = 0;
    xDelta = 0;
    yDelta = 0;
    x = -puttLength * Math.sin(puttTimeRadians);
    y = -puttLength * Math.cos(puttTimeRadians);
    vX = vInitialFtSec * Math.sin(puttAimAngleRadians);
    vY = vInitialFtSec * Math.cos(puttAimAngleRadians);
    v = (vX ** 2 + vY ** 2) ** 0.5;
    phiRadians = Math.acos(vY / (vX ** 2 + vY ** 2 + 0.0000000001) ** 0.5);
    aX =
      (5 / 7) * negMuRFGcostheta * Math.sin(phiRadians) -
      0.00347108729918004 * vX ** 2;
    aY =
      (5 / 7) * (negGSInTheta + negMuRFGcostheta * Math.cos(phiRadians)) -
      0.00347108729918004 * vY ** 2;

    // step 13

    while (t < 9.99) {
      vXPrior = vX;
      vYPrior = vY;
      vPrior = v;
      aXprior = aX;
      aYPrior = aY;
      vX = vX + aX * 0.01;
      vY = vY + aY * 0.01;
      v = (vX ** 2 + vY ** 2) ** 0.5;
      phiRadians = Math.acos(vY / (vX ** 2 + vY ** 2 + 0.0000000001) ** 0.5);
      aX =
        (5 / 7) * negMuRFGcostheta * Math.sin(phiRadians) -
        0.00347108729918004 * vX ** 2;
      aY =
        (5 / 7) * (negGSInTheta + negMuRFGcostheta * Math.cos(phiRadians)) -
        0.00347108729918004 * vY ** 2;


      // step 14
      if (xDelta < -0.00001 || v >= vPrior || vPrior < 0.0) {
        tFinal = t;
        break;
      }

      xDelta = (aXprior + aX) * 0.000025 + vXPrior * 0.01;
      x = x + xDelta;

      yDelta = (aYPrior + aY) * 0.000025 + vYPrior * 0.01;
      y = y + yDelta;


      // if  (x < aXprior ){
      //   x = aXprior
      // }


      let insertData = {
        t: t,
        x: x,
        y: y
      };

      database.push(insertData);

      t = t + 0.01;
    }

    // step 15
    xR = x * Math.cos(puttTimeRadians) - y * Math.sin(puttTimeRadians);
    yR = y * Math.cos(puttTimeRadians) - x * Math.sin(puttTimeRadians);

    // step 16
    yR = -yR;

    // step 17
    let c = document.getElementById("canvas");
    let w = this.state.screenWidth;
    let h = this.state.screenHeight;

    let holeSize = 4.25 * (1 + (puttLength - 2) / 10);
    let ballSize = 1.68 * (1 + (puttLength - 2) / 10);

    let alpha = (puttTimeDegrees * Math.PI) / 180; // replace putt angle with 120 to convert to radians



    if (w > 1024) {
      let headerHeight = document.getElementById("desktop-header").offsetHeight;
      let footerHeight = document.getElementById("desktop-footer").offsetHeight;
      w = w / 2;
      h = h - (headerHeight + footerHeight);
    }


    let headerHeight, sliderHeight, footerHeight;


    if (document.getElementById("mobile-header") && document.getElementById("graph-slides") && document.getElementById("footer")) {

      headerHeight = document.getElementById("mobile-header").offsetHeight;
      sliderHeight = document.getElementById("graph-slides").offsetHeight;
      footerHeight = document.getElementById("footer").offsetHeight;
    }

    if (detectmob()) {
      // h = h - (headerHeight + sliderHeight + footerHeight + 63);
      h = h - (headerHeight + sliderHeight + footerHeight + 53);
    }



    // to detect the Desktop  
    if (detectIPad() === false && detectmob() === false) {
      w = (this.state.screenWidth * 50) / 100;
      document.querySelector('.graph-container').style.width = `${w}px`;
    }

    // to detect the IPAD
    if (detectIPad()) {
      w = (this.state.screenWidth * 100) / 100;
      h = h - (headerHeight + sliderHeight + footerHeight + 75);
      document.querySelector('.graph-container').style.width = `${w}px`;
    }

    let puttLengthInches = puttLength * 12;
    let metric = (h / (topInches + bottomInches + puttLengthInches));


    //Temparory AimPoint Axis
    let tempXAxis =
      puttBreak *
      Math.cos(Math.asin(puttBreak / 2 / puttLengthInches)) *
      metric;

    //Generate HoleXPoint (the center point to draw the graph) commented is from the exact center and uncommented is slightly 50px on a side
    let holeXPoint = mirror ? (w / 2) - 35 + tempXAxis / 2 : (w / 2) + 35 - tempXAxis / 2;

    // let holeCenterX = holeXPoint;
    let holeCenterX = c.width / 2;
    let holeCenterY = topInches * metric;
    let ballCenterX = holeCenterX;
    let ballCenterY = holeCenterY + puttLengthInches * metric;

    //AimPoint calculation
    let aimPointY =
      holeCenterY +
      puttBreak *
      Math.sin(Math.asin(puttBreak / 2 / puttLengthInches)) *
      metric;
    let aimPointX =
      holeCenterX +
      puttBreak *
      Math.cos(Math.asin(puttBreak / 2 / puttLengthInches)) *
      metric;

    if (mirror) {
      aimPointX =
        holeCenterX -
        puttBreak *
        Math.cos(Math.asin(puttBreak / 2 / puttLengthInches)) *
        metric;
    };



    // check and reposition the aimpointX if it goes beyond the screen starts


    if (aimPointX < 0 && mirror) {
      holeCenterX = holeCenterX + Math.abs(aimPointX);
      ballCenterX = holeCenterX;
      aimPointX = 0
    }

    // when mirrored
    if (aimPointX > c.width && !mirror) {

      let aimPointValueExceeded = aimPointX - c.width;

      holeCenterX = holeCenterX - aimPointValueExceeded;
      ballCenterX = holeCenterX;
      aimPointX = aimPointX - aimPointValueExceeded
    }



    // check and reposition the aimpointX if it goes beyond the screen ends







    // check and reposition the holecenterX if the final ball exceed beyond the screen starts




    let finalBallPositonToBeX =
      metric *
      12 *
      (database[database.length - 1].x * Math.cos((puttTimeDegrees * Math.PI) / 180) - database[database.length - 1].y * Math.sin((puttTimeDegrees * Math.PI) / 180)) +
      holeCenterX;


    let finalCoordinate = database[database.length - 1].xP =
      metric *
      12 *
      (database[database.length - 1].x * Math.cos(alpha) - database[database.length - 1].y * Math.sin(alpha)) +
      holeCenterX;


    if (!mirror) {
      finalCoordinate = holeCenterX - finalCoordinate + holeCenterX - (ballSize * metric) / 2
    }





    if (finalBallPositonToBeX > c.width && mirror) {

      let valueExceed = finalBallPositonToBeX - c.width;

      holeCenterX = holeCenterX - valueExceed;
      ballCenterX = holeCenterX;
      aimPointX = holeCenterX +
        puttBreak *
        Math.cos(Math.asin(puttBreak / 2 / puttLengthInches)) *
        metric;

      if (mirror) {
        aimPointX =
          holeCenterX -
          puttBreak *
          Math.cos(Math.asin(puttBreak / 2 / puttLengthInches)) *
          metric;
      };


    }


    if (finalCoordinate < 0) {
      holeCenterX = holeCenterX + Math.abs(finalCoordinate);
      ballCenterX = holeCenterX;
    }



    // check and reposition the holecenterX if the final ball exceed beyond the screen ends


    //Step 18
    let i = 0;
    let DistanceFromHoleCenter;
    let xyDistanceFinalBallToHoleCenter;
    while (i < database.length) {
      database[i].xP =
        metric *
        12 *
        (database[i].x * Math.cos(alpha) - database[i].y * Math.sin(alpha)) +
        holeCenterX;
      database[i].yP =
        -metric *
        12 *
        (database[i].y * Math.cos(alpha) + database[i].x * Math.sin(alpha)) +
        holeCenterY;

      i++;
    }

    if (database[database.length - 1] !== undefined) {

      DistanceFromHoleCenter =
        [(database[database.length - 1].xP - holeCenterX) ** 2 +
          (database[database.length - 1].yP - holeCenterY) ** 2] ** (1 / 2);

      xyDistanceFinalBallToHoleCenter =
        (database[database.length - 1].x ** 2 +
          database[database.length - 1].y ** 2) **
        0.5;


    }






    // let ballSize = 4;

    c.width = w;
    c.height = h;

    let ctx = c.getContext("2d");
    ctx.clearRect(0, 0, c.width, c.height);

    //draw the Shooze
    ctx.save();
    ctx.beginPath();

    // Trignometric calculation for the Each angle of the trangle

    let angle =
      (parseInt(this.state.formData.PuttTime.split(":")[0]) * 60 +
        parseInt(this.state.formData.PuttTime.split(":")[1])) /
      2;

    let shooze1 = document.getElementById("shooze");
    let shooze2 = document.getElementById("shooze2");
    let arrow1 = document.getElementById("arrow1");
    let grass = document.getElementById("grassTexture");
    let whiteBall = document.getElementById("whiteBall");
    let yellowBall = document.getElementById("yellowBall");
    let holeImage = document.getElementById("hole");
    let aimPoint = document.getElementById("aimPoint");
    let puttLengthTriangle = document.getElementById("puttLength");
    let puttTimeTriangle = document.getElementById("puttTime");
    let arrowSlope = document.getElementById("arrowSlope");

    // draw the grass pattern start
    // let pat = ctx.createPattern(grass, "repeat");
    // ctx.rect(0, 0, c.width, c.height);
    // ctx.fillStyle = pat;
    // ctx.fill();
    // draw the grass pattern ends

    drawImage(ctx, grass, 0, 0, c.width, c.height)

    // draw the slope arrow



    drawImage(
      ctx,
      arrowSlope,
      holeCenterX - c.width,
      holeCenterY - 20,
      c.width * 2,
      40,
      360 - 90 + angle + (360 - angle) * 2
    );


    ctx.restore();
    // draw the arrow ends




    // find the angle of the triangle 



    let puttlineHeight = ballCenterY - holeCenterY;

    let aimpointHeight = ballCenterY - aimPointY;

    let puttBreakHeight = holeCenterX - aimPointX


    let aimPointAngle = (puttlineHeight ** 2 + puttBreakHeight ** 2 - aimpointHeight ** 2) / (2 * puttlineHeight * puttBreakHeight);


    aimPointAngle = (Math.acos(aimPointAngle)) * 180 / Math.PI



    // find the angle for the pelz line

    let pelzLineheight = ballCenterY;

    let pelzLineToHoleCenterX = database[database.length - 1].xP - holeCenterX


    let pelzLineAngle = (pelzLineheight ** 2 + pelzLineToHoleCenterX ** 2 - puttlineHeight ** 2) / (2 * pelzLineheight * pelzLineToHoleCenterX);

    pelzLineAngle = (Math.acos(pelzLineAngle)) * 180 / Math.PI



    let getAngle = (pelzLineToHoleCenterX / 40) * 5 

      console.log(puttBreakHeight, "distance")


    // draw labels over line


    


    // draw labels over line



    // Get the triangle coordinates




    let manageMirror = 100;

    if (mirror) {
      manageMirror = 0;
    }



    let puttBreakRotateAngle = 26.561228043043656 * Math.abs(holeCenterY - aimPointY) / 100;

    let rota = !mirror ? -1 * (puttBreakRotateAngle / 1.5) : (puttBreakRotateAngle / 1.5);


    let aimLineWidth = (holeCenterX - aimPointX) / 2;



    let aimLineCenterMirrored = mirror ? -50 : 50;

    let aimLineCenter = aimPointX + aimLineWidth - aimLineCenterMirrored / 2;


    ctx.save();

    if (this.state.tips === "On") {
      rotateOnAxis(ctx, aimLineCenter - 100 + manageMirror, holeCenterY - 70, 100, 52, rota)
    }
    ctx.restore();

    //Draw the Putt break line



    let breakPositionMetric = (puttLength * 2) - 10;


    ctx.lineWidth = 2;
    ctx.save();
    ctx.beginPath();

    ctx.moveTo(holeCenterX, holeCenterY);
    ctx.lineTo(aimPointX, aimPointY);
    ctx.strokeStyle = "black";  // #ffc107 yellow


    // hide PuttBreak
    this.state.puttBreakNew = `${this.state.Output.Break.totalBreakFt ? this.state.Output.Break.totalBreakFt + "'" : ''}${Math.floor(this.state.Output.Break.totalBreakInch)}" `;
    if (this.state.tips === "On") {
      rotateOnAxis(ctx, aimLineCenter - 100 + manageMirror, holeCenterY - 70, 12, 12, rota)


      ctx.fillStyle = "white";
      ctx.shadowColor = "black";
      ctx.shadowBlur = 20;
      ctx.font = "18px Arial";


      ctx.textBaseline = 'middle';
      ctx.textAlign = "center";

      // draw hill label

      ctx.font = "18px Arial";
      ctx.fillStyle = "white";
      ctx.shadowColor = "black";
      ctx.shadowBlur = 20;


      // draw hill label ends

      ctx.textBaseline = 'middle';
      ctx.textAlign = "center";

    }

    ctx.stroke();
    ctx.restore();

    //Draw the Aim Line
    ctx.save();
    ctx.beginPath();
    ctx.lineTo(ballCenterX, ballCenterY);
    ctx.lineTo(aimPointX, aimPointY);
    ctx.strokeStyle = "red";
    ctx.stroke();
    ctx.restore();

    //Draw the Pelz Line

    let pelzpointx = 0;

    if (this.state.showPelzLine === true) {
      // if(this.state.PelzLine === "On"){
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(ballCenterX, ballCenterY);
      if (database[database.length - 1] !== undefined) {
        let x1 = database[0].xP;
        let x2 = database[database.length - 1].xP;
        let y1 = database[0].yP;
        let y2 = database[database.length - 1].yP;

        let deltaY = Math.abs(y2 - ballCenterY);
        let deltaX = Math.abs(x2 - ballCenterX);

        let yRatio = deltaY / deltaX;
        let xRatio = deltaX / deltaY;

        pelzpointx = holeCenterX -
          database[database.length - 1].xP +
          holeCenterX -
          database[database.length - 1].yP * xRatio;
        if (!mirror) {
          ctx.lineTo(
            holeCenterX -
            database[database.length - 1].xP +
            holeCenterX -
            database[database.length - 1].yP * xRatio,
            0
          );
        } else {
          pelzpointx = database[database.length - 1].xP +
            database[database.length - 1].yP * xRatio;
          ctx.lineTo(
            database[database.length - 1].xP +
            database[database.length - 1].yP * xRatio,
            0
          );
        }
      }
      ctx.strokeStyle = "purple";
      ctx.stroke();
      ctx.restore();

    }



    let hillTextBaseX = mirror ? -200 : 100;
    let hillTextBaseY = -50;

    let hillTextX = !mirror ? c.width - 100 : 0;


    let puttLengthBackground = mirror ? 50 : 105;
    // label background for putt length

    let PuttLengthBaseY = mirror ? 0 : 0;

    //Draw the Putt Length Line
    ctx.save();
    ctx.beginPath();
    ctx.lineTo(ballCenterX, ballCenterY);
    ctx.lineTo(holeCenterX, holeCenterY);
    ctx.strokeStyle = "blue";
    let puttLengthText = mirror ? -50 : -100;



    this.playButtonPositon = `${h / 2 - 30 + PuttLengthBaseY + 100}px`;

    if (this.state.playButtonPositon != `${h / 2 - 30 + PuttLengthBaseY + 70}px`) {
      this.setState({
        playButtonPositon: `${h / 2 - 30 + PuttLengthBaseY + 70}px`,
        PelzLinePosition: `${h / 2 - 30 + PuttLengthBaseY + 120}px`,

      })
    }





    ctx.stroke();
    ctx.restore();


   

    //Draw the Hole
    ctx.save();
    ctx.beginPath();
    drawImage(
      ctx,
      holeImage,
      holeCenterX - (holeSize * metric) / 2,
      holeCenterY - (holeSize * metric) / 2,
      // 60,
      // 60,
      holeSize * metric,
      holeSize * metric
    );
    ctx.strokeStyle = "green";
    ctx.stroke();
    ctx.restore();

    // ctx.save();
    // ctx.beginPath();
    // ctx.fillStyle = "#FF0000";
    // ctx.fillRect(holeCenterX - (holeSize * metric) / 2, holeCenterY - (holeSize * metric) / 2, holeSize * metric, 10);
    // ctx.restore();

    // five cirles of aim point
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = "red";
    ctx.font = "18px Arial";
    if (mirror) {


      this.animateButtonPosition = true
    } else {
      this.animateButtonPosition = false

    }



    ctx.drawImage(aimPoint, aimPointX - 15, aimPointY - 15, 30, 30);
    ctx.stroke();
    ctx.restore();


    let puttPowerLabelBgX = mirror ? ballCenterX - 50 : ballCenterX - 50;
    let puttPowerLabelBgY = ballCenterY + 10;
    if (this.state.PuttPowerSwitch === "On") {


    }
    //Draw the label putt power and Start ball
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = "black";
    ctx.fillStyle = "white";
    ctx.shadowColor = "none";
    ctx.shadowBlur = 20;


    // draw the first ball
    drawImage(
      ctx,
      whiteBall,
      ballCenterX - (ballSize * metric) / 2,
      ballCenterY - (ballSize * metric) / 2,
      ballSize * metric,
      ballSize * metric
    );

    // hide PuttPower
    this.state.puttPowerNew = this.state.Output.PuttPower < 0 ? 0 : this.state.Output.PuttPower;



    let crossedForCurve = false;
    //Draw the curv
    ctx.save();
    ctx.beginPath();
    ctx.globalAlpha = 0.06;
    for (let ii = 1; ii < database.length - 50; ii++) {
      ctx.fillStyle = "#f1f1f1";
      if (database[ii].yP < holeCenterY)
        crossedForCurve = true;
      if (database[ii].yP < holeCenterY - (holeSize * metric) / 2 || crossedForCurve === true)  {
        // console.log('passeddddlets see',database[ii].yP, holeCenterY + (holeSize * metric) / 2)
        ctx.fillStyle = "yellow";
        ctx.globalAlpha = 0.04;
      }
      if (!mirror) {
        ctx.fillRect(
          holeCenterX - database[ii].xP + holeCenterX - (ballSize * metric) / 2,
          database[ii].yP - (ballSize * metric) / 2,
          ballSize * metric,
          ballSize * metric
        );
      } else {
        ctx.fillRect(
          database[ii].xP - (ballSize * metric) / 2,
          database[ii].yP - (ballSize * metric) / 2,
          ballSize * metric,
          ballSize * metric
        );
      }
    }
    ctx.globalAlpha = 1.0;
    ctx.stroke();
    ctx.restore();



    let crossedForballs = false;
    // draw the balls
    let noOfBalls = this.numberOfBalls(parseInt(puttLength));
    let ballDistance = parseInt(database.length / noOfBalls, 10);
    // ballDistance = ballDistance + 7;
    // console.log('database.length', database.length, 'ballDistance', ballDistance)
    var balli = 1;
    clearInterval(this.ballTimer);

    if (this.state.stopAnimation === false) {
      console.log('this.state.stopAnimation ')
      this.ballTimer = setInterval(() => {
        if (database[ballDistance * balli] != undefined) {

          ctx.save();
          ctx.beginPath();
          ctx.strokeStyle = "black";
          if (balli == noOfBalls - 1) {

            balli = 1
            this.calculateDynamicCoordinates();
          }
          if (mirror) {
            drawImage(
              ctx,
              database[ballDistance * balli].yP > holeCenterY
                ? whiteBall
                : yellowBall,
              database[ballDistance * balli].xP - (ballSize * metric) / 2,
              database[ballDistance * balli].yP - (ballSize * metric) / 2,
              ballSize * metric,
              ballSize * metric
            );


          } else {
            drawImage(
              ctx,
              database[ballDistance * balli].yP > holeCenterY
                ? whiteBall
                : yellowBall,
              holeCenterX -
              database[ballDistance * balli].xP +
              holeCenterX -
              (ballSize * metric) / 2,
              database[ballDistance * balli].yP - (ballSize * metric) / 2,
              ballSize * metric,
              ballSize * metric
            );

          }
          ctx.stroke();
          ctx.restore();
          balli++;
        } else {
          clearInterval(this.ballTimer);
        }
      }, (parseInt(tFinal) / (noOfBalls - 1) / this.state.animateSpeed) * 1000);
    } else {

      for (let loop = 1; loop < noOfBalls - 1; loop++) {
        ctx.beginPath();
      
        if (mirror && database[ballDistance * loop] != undefined) {
         
          drawImage(
            ctx,
            
            ((database[ballDistance * loop].yP - (ballSize * metric) / 2) + ballSize * metric <= holeCenterY - (holeSize * metric) / 2 )
              || crossedForballs === true
              ? yellowBall
              : whiteBall,
            database[ballDistance * loop].xP - (ballSize * metric) / 2,
            database[ballDistance * loop].yP - (ballSize * metric) / 2,
            ballSize * metric,
            ballSize * metric
          );
          if (crossedForballs === false) {
            if ((database[ballDistance * loop].yP - (ballSize * metric) / 2) + ballSize * metric < ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
              &&
              (database[ballDistance * loop].xP - (ballSize * metric) / 2) - 2> holeCenterX - (holeSize * metric) / 2
              &&
              (database[ballDistance * loop].xP - (ballSize * metric) / 2) < ((holeCenterX - (holeSize * metric) / 2) + holeSize * metric))
              crossedForballs = true;
            else if ((database[ballDistance * loop].yP - (ballSize * metric) / 2) < ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
              &&
              ((database[ballDistance * (loop + 1)].yP - (ballSize * metric) / 2) + (ballSize * metric) - 2<= holeCenterY - (holeSize * metric) / 2))
              crossedForballs = true;
          
          }
         
        } else if (database[ballDistance * loop] != undefined) {
          
          drawImage(
            ctx,
            ((database[ballDistance * loop].yP - (ballSize * metric) / 2) + ballSize * metric <= holeCenterY - (holeSize * metric) / 2 )
              || crossedForballs === true
              ? yellowBall
              : whiteBall,
            holeCenterX -
            database[ballDistance * loop].xP +
            holeCenterX -
            (ballSize * metric) / 2,
            database[ballDistance * loop].yP - (ballSize * metric) / 2,
            ballSize * metric,
            ballSize * metric
          );
          
          if ( crossedForballs === false){
            if ((database[ballDistance * loop].yP - (ballSize * metric) / 2) + ballSize * metric< ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
              &&
              (holeCenterX -
                database[ballDistance * loop].xP +
                holeCenterX -
              (ballSize * metric) / 2) + ballSize * metric > holeCenterX - (holeSize * metric) / 2
              &&
              (holeCenterX -
                database[ballDistance * loop].xP +
                holeCenterX -
                (ballSize * metric) / 2) + (ballSize * metric) +1 < ((holeCenterX - (holeSize * metric) / 2) + holeSize * metric))
              crossedForballs = true;
            else if ((database[ballDistance * loop].yP - (ballSize * metric) / 2) < ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
              &&
              ((database[ballDistance * (loop + 1)].yP - (ballSize * metric) / 2) + (ballSize * metric) - 2 <= holeCenterY - (holeSize * metric) / 2))
              crossedForballs = true;
         
          }
      

        }
        ctx.stroke();


      }
    }
    if (database[database.length - 1] !== undefined) {
      if (mirror) {
        
        drawImage(
          ctx,
          ((database[database.length - 1].yP - (ballSize * metric) / 2) + ballSize * metric <= holeCenterY - (holeSize * metric) / 2 )
            || crossedForballs === true
            ? yellowBall
            : whiteBall,
          database[database.length - 1].xP - (ballSize * metric) / 2,
          database[database.length - 1].yP - (ballSize * metric) / 2,
          ballSize * metric,
          ballSize * metric
        );
        if (mirror) {
          if (database[database.length - 1].yP < ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
            &&
            (database[database.length - 1].xP - (ballSize * metric) / 2) > holeCenterX - (holeSize * metric) / 2
            &&
            (database[database.length - 1].xP - (ballSize * metric) / 2) < ((holeCenterX - (holeSize * metric) / 2) + holeSize * metric))
            crossedForballs = true;
          
        }
        else {
          if (database[database.length - 1].yP < ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
            &&
            (database[database.length - 1].xP - (ballSize * metric) / 2) + ballSize * metric > holeCenterX - (holeSize * metric) / 2
            &&
            (database[database.length - 1].xP - (ballSize * metric) / 2) + ballSize * metric < ((holeCenterX - (holeSize * metric) / 2) + holeSize * metric))
            crossedForballs = true;
        }
       
      } else {
       
        drawImage(
          ctx,
          ((database[database.length - 1].yP - (ballSize * metric) / 2) + ballSize * metric <= holeCenterY - (holeSize * metric) / 2 )
            || crossedForballs === true
            ? yellowBall
            : whiteBall,
          holeCenterX -
          database[database.length - 1].xP +
          holeCenterX -
          (ballSize * metric) / 2,
          database[database.length - 1].yP - (ballSize * metric) / 2,
          ballSize * metric,
          ballSize * metric
        );
        if (mirror) {
          if (database[database.length - 1].yP < ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
            &&
            (holeCenterX -
              database[database.length - 1].xP +
              holeCenterX -
              (ballSize * metric) / 2) > holeCenterX - (holeSize * metric) / 2
            &&
            (holeCenterX -
              database[database.length - 1].xP +
              holeCenterX -
              (ballSize * metric) / 2)< ((holeCenterX - (holeSize * metric) / 2) + holeSize * metric))
            crossedForballs = true;
        }
        else {
          if (database[database.length - 1].yP < ((holeCenterY - (holeSize * metric) / 2) + holeSize * metric)
            &&
            (holeCenterX -
              database[database.length - 1].xP +
              holeCenterX -
            (ballSize * metric) / 2) + ballSize * metric > holeCenterX - (holeSize * metric) / 2
            &&
            (holeCenterX -
              database[database.length - 1].xP +
              holeCenterX -
              (ballSize * metric) / 2) + ballSize * metric < ((holeCenterX - (holeSize * metric) / 2) + holeSize * metric))
            crossedForballs = true;
        }
      
      }
    }



    // lebels for the Pelz line and AimLine



    // solution for the aimline label goes away

    let percentageOfputtBreakHeight = puttBreakHeight * 14 / 100;

    console.log(percentageOfputtBreakHeight, "percentageOfputtBreakHeight");


    let makeSpaceBetweenLabeles =  Math.abs(puttBreakHeight) > 30 ? 0 : 20;
    console.log(Math.round(ballCenterX), ballCenterY, aimPointX, aimPointY, Math.round(pelzpointx))
    makeSpaceBetweenLabeles = !mirror ? -1 * makeSpaceBetweenLabeles : makeSpaceBetweenLabeles;
    console.log('makeSpaceBetweenLabeles', makeSpaceBetweenLabeles)
    // if (Math.round(ballCenterX) == Math.round(aimPointX) && Math.round(pelzpointx) == Math.round(ballCenterX)){
    //   console.log('heree');
    //   drawLabel(ctx, "AimLine", { x: ballCenterX, y: ballCenterY }, { x: aimPointX, y: aimPointY }, 'center', 30, mirror ? -10 : 25, -45);
    // }
    // else {
      drawLabel(ctx, "AimLine", { x: ballCenterX, y: ballCenterY }, { x: aimPointX, y: aimPointY }, 'center', 0, mirror ? -10 : 25, 0);
    // }
   
    // drawLabelAndRotate(ctx, "AimLine", mirror ? (-percentageOfputtBreakHeight + (-makeSpaceBetweenLabeles + holeCenterX + aimPointX) / 2 - 30): (-percentageOfputtBreakHeight + (-makeSpaceBetweenLabeles + holeCenterX + aimPointX) / 2 - 15), ballCenterY / 2, 10 * 7, 10, Math.abs(aimPointAngle - 180) || 90 )
    
    this.state.showPelzLine && drawLabel(ctx, "PelzLine", { x: ballCenterX, y: ballCenterY }, {
      x: pelzpointx, y: 0
    }, 'center', 0, mirror ? 25 : -10, 0);
    crossedForballs = false;
     
  //  this.state.showPelzLine && drawLabelAndRotate(ctx, "PelzLine", mirror ? (makeSpaceBetweenLabeles + holeCenterX + (pelzLineToHoleCenterX / 2)) - 30 : (makeSpaceBetweenLabeles + holeCenterX - (pelzLineToHoleCenterX / 2)) - 20, ballCenterY / 2, 10 * 7, 10, 90 - getAngle)

  };


  /**
  * @todo This method handles number of balls to be displayed based on the putt length
  * @param {string} puttlength
  * @return {object} 
  */

  numberOfBalls = puttLength => {
    let balls = 10;

    switch (puttLength) {
      case 2:
        balls = 8;
        break;
      case 3:
        balls = 9;
        break;
      case 4:
        balls = 10;
        break;
      case 5:
        balls = 11;
        break;
      case 6:
        balls = 11;
        break;
      case 7:
        balls = 11;
        break;
      case 8:
        balls = 12;
        break;
      case 9:
        balls = 12;
        break;
      case 10:
        balls = 12;
        break;
      case 11:
        balls = 13;
        break;
      case 12:
        balls = 13;
    }

    return balls;
  };


  /**
   * @todo This method will convert the Time into degrees and Radians
   * @param {string} puttTime
   * @return {float} 
   */

  getDegreeAndRadiansByTime = puttTime => {
    let puttTimeSplit = puttTime.split(":");
    puttTime = puttTimeSplit[0] + puttTimeSplit[1];


    const object = {
      "600": 0.0,
      "615": 7.5,
      "630": 15.0,
      "645": 22.5,
      "700": 30.0,
      "715": 37.5,
      "730": 45.0,
      "745": 52.5,
      "800": 60.0,
      "815": 67.5,
      "830": 75.0,
      "845": 82.5,
      "900": 90.0,
      "915": 97.5,
      "930": 105.0,
      "945": 112.5,
      "1000": 120.0,
      "1015": 127.5,
      "1030": 135.0,
      "1045": 142.5,
      "1100": 150.0,
      "1115": 157.5,
      "1130": 165.0,
      "1145": 172.5,
      "1200": 180.0,
      "545": 7.5,
      "530": 15.0,
      "515": 22.5,
      "500": 30.0,
      "445": 37.5,
      "430": 45.0,
      "415": 52.5,
      "400": 60.0,
      "345": 67.5,
      "330": 75.0,
      "315": 82.5,
      "300": 90.0,
      "245": 97.5,
      "230": 105.0,
      "215": 112.5,
      "200": 120.0,
      "145": 127.5,
      "130": 135.0,
      "115": 142.5,
      "100": 150.0,
      "045": 157.5,
      "030": 165.0,
      "015": 172.5,
      "000": 180.0
    };
    return object[puttTime];

  };
  refreshonorientationchanges = async (orientation, type) => {
    console.log('orientationtype', orientation, type)
    if (orientation.includes('portrait') && document.getElementById("canvas") !== undefined && document.getElementById("canvas") !== null) {
      this.getDefaultValue();

      // this.setState({
      //   screenWidth: window.innerWidth,
      //   screenHeight: window.innerHeight
      // });
      await this.setState({
        orientationchanged: true
      });
      this.calculateDynamicCoordinates();
      this.setState({
        orientationchanged: false
      })
    }
  }
  render() {
    if (
      this.state.formData.GreenSpeed > 0 &&
      this.state.formData.PLength > 0 &&
      this.state.formData.PuttTime != "0:00" &&
      this.state.showStatus
    ) {
      if (this.state.deaultValue.length > 0) {
        this.getCalculateValue();
      }
    }
    return (
      <React.Fragment>
        <MediaQuery maxWidth={870}>
          <DeviceOrientation lockOrientation={'portrait'} onOrientationChange={this.refreshonorientationchanges} >
            <Orientation orientation='portrait' alwaysRender={false}>
              <React.Fragment>
        <div className="graph-container main-container ">

          <div className="graph-header">
            <div className="header-container" >
              <div className="putt-break puttbreak-value">
                {this.state.puttBreakNew}
              </div>
              <div className="header-labels" >

                <div className="header-labels-flex" >
                  <div className="header-puttbreak-text">PuttBreak<sup>TM</sup></div>
                  <div className="header-puttpower-text">PuttPower<sup>TM</sup></div>
                </div>

                <div className="right_center">
                  {this.getClockMirrorData().positionText} {this.getClockMirrorData().hillText}
                </div>

              </div>
              <div className="putt-break puttpower-value">
                {this.state.puttPowerNew}<sup>F</sup>
              </div>
            </div>
          </div>



          <div className="container">
            <div className="row">
              {/* triangle html canvas start*/}
              <div
                className="col-md-12 col-lg-7 col-sm-12 mp-0 grassbg"
                id="container"
              >
                <button
                  onClick={() => {
                    if (this.state.animateButtonText === "Putt") {
                      this.setState({
                        timerTime: 80,
                        animateSpeed: 1,
                        animateButtonText: "1x",
                        stopAnimation: false
                      });
                    }
                  }}
                  className="btn btn-red btn-lg animate"
                  style={this.animateButtonPosition ? {
                    fontWeight: 600,
                    position: "absolute",
                    top: '47px',
                    left: this.state.stopAnimation != false ? "6px" : "8px",
                    zIndex: 10,
                    fontFamily: "'Barlow Condensed', sans-serif"
                  } : {
                      fontWeight: 600,
                      position: "absolute",
                      top: '47px',
                      right: this.state.stopAnimation != false ? "6px" : "8px",
                      zIndex: 10,
                      fontFamily: "'Barlow Condensed', sans-serif"
                    }}
                >

                  {this.state.animateButtonText != 'Putt' ? this.processAimateButtonUI() : this.state.animateButtonText}{this.state.animateButtonText === 'Putt' && <i class="fa fa-play putt-play-icon" aria-hidden="true"></i>}
                </button>




                {this.state.animateButtonText !== "4x" && this.state.stopAnimation !== true && <button
                  onClick={() => {
                    if (this.state.animateButtonText === "1x") {
                      this.setState({
                        timerTime: 80,
                        animateSpeed: 2,
                        animateButtonText: "2x",
                        stopAnimation: false
                      });
                    } else if (this.state.animateButtonText === "2x") {
                      this.setState({
                        timerTime: 80,
                        animateSpeed: 4,
                        animateButtonText: "4x",
                        stopAnimation: false
                      });
                    } 
                  }}
                  className="btn btn-red btn-lg animate"
                  style={this.animateButtonPosition ? {
                    fontWeight: 600,
                    position: "absolute",
                    top: '47px',
                    left: this.state.stopAnimation != false ? "79px" : "57px",
                    zIndex: 10,
                    fontFamily: "'Barlow Condensed', sans-serif"
                  } : {
                      fontWeight: 600,
                      position: "absolute",
                      top: '47px',
                      right: this.state.stopAnimation != false ? "79px" : "57px",
                      zIndex: 10,
                      fontFamily: "'Barlow Condensed', sans-serif"
                    }}
                >

                  {this.state.animateButtonText != 'Putt' ? this.processAimateButtonUI2() : this.state.animateButtonText}{this.state.animateButtonText === 'Putt' && <i class="fa fa-play putt-play-icon" aria-hidden="true"></i>}
                </button>}



                {this.state.animateButtonText === "4x" && <button
                  onClick={() => {
                    this.setState({
                      timerTime: 0,
                      animateSpeed: 0,
                      animateButtonText: "Putt",
                      stopAnimation: true
                    });
                  }}
                  className="btn btn-red btn-lg animate"
                  style={this.animateButtonPosition ? {
                    fontWeight: 600,
                    position: "absolute",
                    top: '47px',
                    left: "81px",
                    zIndex: 10,
                    fontFamily: "'Barlow Condensed', sans-serif"
                  } : {
                      fontWeight: 600,
                      position: "absolute",
                      top: '47px',
                      right: "81px",
                      zIndex: 10,
                      fontFamily: "'Barlow Condensed', sans-serif"
                    }}
                >
                  Stop
          </button>}



                <button
                  onClick={() => {
                    this.setState({
                      showPelzLine: !this.state.showPelzLine
                    })
                  }}
                  className="btn btn-purple btn-lg animate"
                  style={this.animateButtonPosition ? {
                    fontWeight: 600,
                    position: "absolute",
                    right: this.state.PelzLinePosition,
                    top: '3px',
                    width: '80px',
                    left: "6px",
                    zIndex: 10,
                    background: this.state.showPelzLine ? "purple" : "#3e3e3e",
                    color: "white",
                    fontFamily: "'Barlow Condensed', sans-serif"
                  } : {
                      fontWeight: 600,
                      position: "absolute",
                      right: this.state.PelzLinePosition,
                      top: '3px',
                      right: "6px",
                      width: '80px',
                      zIndex: 10,
                      background: this.state.showPelzLine ? "purple" : "#3e3e3e",
                      color: "white",
                      fontFamily: "'Barlow Condensed', sans-serif"
                    }}
                >
                  PelzLine<sup>TM</sup>
                </button>


                <img
                  id="arrowSlope"
                  width="0"
                  height="0"
                  src={LARGESLOPEARROW}
                  alt="arrowSlope"
                />

                <img
                  id="puttLength"
                  width="0"
                  height="0"
                  src={PUTTLENGTHTRIANGLE}
                  alt="puttLength"
                />

                <img
                  id="puttTime"
                  width="0"
                  height="0"
                  src={PUTTTIMETRIANGLE}
                  alt="puttTime"
                />

                <img
                  id="aimPoint"
                  width="0"
                  height="0"
                  src={AIMPOINT}
                  alt="aimPoint"
                />

                <img
                  id="whiteBall"
                  width="0"
                  height="0"
                  src={WHITEBALL}
                  alt="whiteBall"
                />

                <img
                  id="yellowBall"
                  width="0"
                  height="0"
                  src={YELLOWBALL}
                  alt="yellowBall"
                />

                <img
                  id="grassTexture"
                  width="0"
                  height="0"
                  src={GRASSTEXTURE}
                  alt="grassTexture"
                />

                <img id="hole" width="0" height="0" src={HOLE} alt="hole" />

                <img
                  id="shooze"
                  width="0"
                  height="0"
                  src={SHOOZE}
                  alt="Sooze"
                />
                <img
                  id="shooze2"
                  width="0"
                  height="0"
                  src={SHOOZE2}
                  alt="Sooze"
                />
                <img
                  id="arrow1"
                  width="0"
                  height="0"
                  src={ARROW1}
                  alt="Sooze"
                />
                <img
                  id="arrow2"
                  width="0"
                  height="0"
                  src={ARROW2}
                  alt="Sooze"
                />
                <img
                  id="arrowleft1"
                  width="0"
                  height="0"
                  src={ARROWLEFT1}
                  alt="Sooze"
                />
                <img
                  id="arrowleft2"
                  width="0"
                  height="0"
                  src={ARROWLEFT1}
                  alt="Sooze"
                />
                <canvas id="canvas" />
              </div>
              {/* triangle html canvas end*/}

              {this.state.showSliders === false && (
                <div className="vSliderBox">
                  <div className="vSlider vPLength">
                    <span class="slider-text-container">PuttLength<sup>TM</sup></span>
                    <span class="slider-value-container"> {this.calculateInFeetnInch(
                      this.state.PLength.currentValue
                    )}</span>
                    <span onClick={() =>
                      this.decreaseSliderValue("PLength")
                    } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                    <span onClick={() =>
                      this.increaseSliderValue("PLength")
                            } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                    <ReactBootstrapSlider
                      reversed={true}
                      value={this.state.PLength.currentValue}
                      change={e => this.changeSliderValue(e, "PLength")}
                      slideStop={e => this.changeSliderValue(e, "PLength")}
                      formatter={this.calculateInFeetnInch}
                      step={this.state.PLength.step}
                      max={this.state.PLength.max}
                      min={this.state.PLength.min}
                      orientation="vertical"
                    />
                  </div>

                  <div className="vSlider vPuttTime">
                    <span class="slider-text-container">PuttTime<sup>TM</sup></span>
                    <span class="slider-value-container"> {this.puttTimeReformater(this.calculatePuttTime(
                      this.state.PuttTime.currentValue
                    ))}</span>
                    <span onClick={() =>
                      this.decreaseSliderValue("PuttTime")
                    } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                    <span onClick={() =>
                      this.increaseSliderValue("PuttTime")
                            } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                    <ReactBootstrapSlider
                      value={this.state.PuttTime.currentValue}
                      change={e => this.changeSliderValue(e, "PuttTime")}
                      slideStop={e => this.changeSliderValue(e, "PuttTime")}
                      data-action={"PuttTime"}
                      name="PuttTime"
                      step={this.state.PuttTime.step}
                      max={this.state.PuttTime.max}
                      min={this.state.PuttTime.min}
                      orientation="vertical"
                      reversed={true}
                    />
                  </div>

                  <div className="vSlider vPASlope">
                    <span class="slider-text-container">PinAreaSlope<sup>TM</sup></span>
                    <span class="slider-value-container">{this.state.PASlope.currentValue}<span className="paslope_percentage">%</span></span>
                    <span onClick={() =>
                      this.decreaseSliderValue("PASlope")
                    } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                    <span onClick={() =>
                      this.increaseSliderValue("PASlope")
                            } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                    <ReactBootstrapSlider
                      value={this.state.PASlope.currentValue}
                      change={e => this.changeSliderValue(e, "PASlope")}
                      slideStop={e => this.changeSliderValue(e, "PASlope")}
                      step={this.state.PASlope.step}
                      max={this.state.PASlope.max}
                      min={this.state.PASlope.min}
                      orientation="vertical"
                      reversed={true}
                    />
                  </div>

                  <div className="vSlider vGreenSpeed">
                    <span class="slider-text-container">GreenSpeed</span>
                    <span class="slider-value-container">{this.calculateInFeetnInch(
                      this.state.GreenSpeed.currentValue
                    )}</span>
                    <span onClick={() =>
                      this.decreaseSliderValue("GreenSpeed")
                    } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                    <span onClick={() =>
                      this.increaseSliderValue("GreenSpeed")
                            } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                    <ReactBootstrapSlider
                      value={this.state.GreenSpeed.currentValue}
                      change={e => this.changeSliderValue(e, "GreenSpeed")}
                      slideStop={e => this.changeSliderValue(e, "GreenSpeed")}
                      formatter={this.calculateInFeetnInch}
                      step={this.state.GreenSpeed.step}
                      max={this.state.GreenSpeed.max}
                      min={this.state.GreenSpeed.min}
                      orientation="vertical"
                      reversed={true}
                    />
                  </div>
                </div>
              )}

              <div
                className="graphical-view-slider col-md-5 col-lg-5 col-sm-12"
                id="graph-slides-wrapper"
              >

                <div className="graph-slides" id="graph-slides">

                  {this.state.showSliders === true && <div

                    className="graph-input"
                  >
                    <ul className="g-slider putt-time">
                      <li>
                        <p>
                          <span className="g-head">
                            PuttTime<sup>TM</sup>
                          </span>
                          <span className="g-value">
                            {this.puttTimeReformater(this.calculatePuttTime(
                              this.state.PuttTime.currentValue
                            ))}
                          </span>

                          <ReactBootstrapSlider
                            value={this.state.PuttTime.currentValue}
                            change={e => this.changeSliderValue(e, "PuttTime")}
                            slideStop={e =>
                              this.changeSliderValue(e, "PuttTime")
                            }
                            data-action={"PuttTime"}
                            name="PuttTime"
                            step={this.state.PuttTime.step}
                            max={this.state.PuttTime.max}
                            min={this.state.PuttTime.min}
                            orientation="horizontal"
                          />

                          <span className="plus controlSlider">
                            <i
                              className="fa fa-minus"
                              aria-hidden="true"
                              onClick={() =>
                                this.decreaseSliderValue("PuttTime")
                              }
                            />
                          </span>
                          <span className="minus controlSlider">
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              onClick={() =>
                                this.increaseSliderValue("PuttTime")
                              }
                            />
                          </span>
                        </p>
                      </li>
                    </ul>

                    <ul className="g-slider putt-length">
                      <li>
                        <p>
                          <span className="g-head">
                            PuttLength<sup>TM</sup>
                          </span>
                          <span className="g-value">
                            {this.calculateInFeetnInch(
                              this.state.PLength.currentValue
                            )}
                          </span>

                          <ReactBootstrapSlider
                            value={this.state.PLength.currentValue}
                            change={e => this.changeSliderValue(e, "PLength")}
                            slideStop={e =>
                              this.changeSliderValue(e, "PLength")
                            }
                            formatter={this.calculateInFeetnInch}
                            step={this.state.PLength.step}
                            max={this.state.PLength.max}
                            min={this.state.PLength.min}
                            orientation="horizontal"
                          />
                          <span className="plus controlSlider">
                            <i
                              className="fa fa-minus"
                              aria-hidden="true"
                              onClick={() =>
                                this.decreaseSliderValue("PLength")
                              }
                            />
                          </span>
                          <span className="minus controlSlider">
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              onClick={() =>
                                this.increaseSliderValue("PLength")
                              }
                            />
                          </span>
                        </p>
                      </li>
                    </ul>

                    <ul className="g-slider pin-area">
                      <li>
                        <p>
                          <span className="g-head">
                            PinAreaSlope<sup>TM</sup>
                          </span>
                          <span className="g-value">
                            {this.state.PASlope.currentValue}%
                          </span>

                          <ReactBootstrapSlider
                            value={this.state.PASlope.currentValue}
                            change={e => this.changeSliderValue(e, "PASlope")}
                            slideStop={e =>
                              this.changeSliderValue(e, "PASlope")
                            }
                            step={this.state.PASlope.step}
                            max={this.state.PASlope.max}
                            min={this.state.PASlope.min}
                            orientation="horizontal"
                          />
                          <span className="plus controlSlider">
                            <i
                              className="fa fa-minus"
                              aria-hidden="true"
                              onClick={() =>
                                this.decreaseSliderValue("PASlope")
                              }
                            />
                          </span>
                          <span className="minus controlSlider">
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              onClick={() =>
                                this.increaseSliderValue("PASlope")
                              }
                            />
                          </span>
                        </p>
                      </li>
                    </ul>

                    <ul className="g-slider green-speed">
                      <li>
                        <p>
                          <span className="g-head">GreenSpeed</span>
                          <span className="g-value">
                            {this.calculateInFeetnInch(
                              this.state.GreenSpeed.currentValue
                            )}
                          </span>

                          <ReactBootstrapSlider
                            value={this.state.GreenSpeed.currentValue}
                            change={e =>
                              this.changeSliderValue(e, "GreenSpeed")
                            }
                            slideStop={e =>
                              this.changeSliderValue(e, "GreenSpeed")
                            }
                            formatter={this.calculateInFeetnInch}
                            step={this.state.GreenSpeed.step}
                            max={this.state.GreenSpeed.max}
                            min={this.state.GreenSpeed.min}
                            orientation="horizontal"
                          />

                          <span className="plus controlSlider">
                            <i
                              className="fa fa-minus"
                              aria-hidden="true"
                              onClick={() =>
                                this.decreaseSliderValue("GreenSpeed")
                              }
                            />
                          </span>
                          <span className="minus controlSlider">
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              onClick={() =>
                                this.increaseSliderValue("GreenSpeed")
                              }
                            />
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>}





                </div>
              </div>
            </div>
          </div>
                </div>
              </React.Fragment>
            </Orientation>
            <Orientation orientation='landscape' alwaysRender={false} onOrientationChange={this.refreshonorientationchanges} >
              <React.Fragment>
                <div className="main-container rotateImagecontainer">
                  <img src={ROTATE} alt="" className="rotateImage" />
                </div>
              </React.Fragment>
            </Orientation>
          </DeviceOrientation>
        </MediaQuery>
        <MediaQuery minWidth={871} onChange={this.handleMediaQueryChange}>
          <div className="graph-container main-container ">

            <div className="graph-header">
              <div className="header-container" >
                <div className="putt-break puttbreak-value">
                  {this.state.puttBreakNew}
                </div>
                <div className="header-labels" >

                  <div className="header-labels-flex" >
                    <div className="header-puttbreak-text">PuttBreak<sup>TM</sup></div>
                    <div className="header-puttpower-text">PuttPower<sup>TM</sup></div>
                  </div>

                  <div className="right_center">
                    {this.getClockMirrorData().positionText} {this.getClockMirrorData().hillText}
                  </div>

                </div>
                <div className="putt-break puttpower-value">
                  {this.state.puttPowerNew}<sup>F</sup>
                </div>
              </div>
            </div>



            <div className="container">
              <div className="row">
                {/* triangle html canvas start*/}
                <div
                  className="col-md-12 col-lg-7 col-sm-12 mp-0 grassbg"
                  id="container"
                >
                  <button
                    onClick={() => {
                      if (this.state.animateButtonText === "Putt") {
                        this.setState({
                          timerTime: 80,
                          animateSpeed: 1,
                          animateButtonText: "1x",
                          stopAnimation: false
                        });
                      }
                    }}
                    className="btn btn-red btn-lg animate"
                    style={this.animateButtonPosition ? {
                      fontWeight: 600,
                      position: "absolute",
                      top: '47px',
                      left: this.state.stopAnimation != false ? "6px" : "8px",
                      zIndex: 10,
                      fontFamily: "'Barlow Condensed', sans-serif"
                    } : {
                        fontWeight: 600,
                        position: "absolute",
                        top: '47px',
                        right: this.state.stopAnimation != false ? "6px" : "8px",
                        zIndex: 10,
                        fontFamily: "'Barlow Condensed', sans-serif"
                      }}
                  >

                    {this.state.animateButtonText != 'Putt' ? this.processAimateButtonUI() : this.state.animateButtonText}{this.state.animateButtonText === 'Putt' && <i class="fa fa-play putt-play-icon" aria-hidden="true"></i>}
                  </button>




                  {this.state.animateButtonText !== "4x" && this.state.stopAnimation !== true && <button
                    onClick={() => {
                      if (this.state.animateButtonText === "1x") {
                        this.setState({
                          timerTime: 80,
                          animateSpeed: 2,
                          animateButtonText: "2x",
                          stopAnimation: false
                        });
                      } else if (this.state.animateButtonText === "2x") {
                        this.setState({
                          timerTime: 80,
                          animateSpeed: 4,
                          animateButtonText: "4x",
                          stopAnimation: false
                        });
                      }
                    }}
                    className="btn btn-red btn-lg animate"
                    style={this.animateButtonPosition ? {
                      fontWeight: 600,
                      position: "absolute",
                      top: '47px',
                      left: this.state.stopAnimation != false ? "79px" : "57px",
                      zIndex: 10,
                      fontFamily: "'Barlow Condensed', sans-serif"
                    } : {
                        fontWeight: 600,
                        position: "absolute",
                        top: '47px',
                        right: this.state.stopAnimation != false ? "79px" : "57px",
                        zIndex: 10,
                        fontFamily: "'Barlow Condensed', sans-serif"
                      }}
                  >

                    {this.state.animateButtonText != 'Putt' ? this.processAimateButtonUI2() : this.state.animateButtonText}{this.state.animateButtonText === 'Putt' && <i class="fa fa-play putt-play-icon" aria-hidden="true"></i>}
                  </button>}



                  {this.state.animateButtonText === "4x" && <button
                    onClick={() => {
                      this.setState({
                        timerTime: 0,
                        animateSpeed: 0,
                        animateButtonText: "Putt",
                        stopAnimation: true
                      });
                    }}
                    className="btn btn-red btn-lg animate"
                    style={this.animateButtonPosition ? {
                      fontWeight: 600,
                      position: "absolute",
                      top: '47px',
                      left: "81px",
                      zIndex: 10,
                      fontFamily: "'Barlow Condensed', sans-serif"
                    } : {
                        fontWeight: 600,
                        position: "absolute",
                        top: '47px',
                        right: "81px",
                        zIndex: 10,
                        fontFamily: "'Barlow Condensed', sans-serif"
                      }}
                  >
                    Stop
          </button>}



                  <button
                    onClick={() => {
                      this.setState({
                        showPelzLine: !this.state.showPelzLine
                      })
                    }}
                    className="btn btn-purple btn-lg animate"
                    style={this.animateButtonPosition ? {
                      fontWeight: 600,
                      position: "absolute",
                      right: this.state.PelzLinePosition,
                      top: '3px',
                      width: '80px',
                      left: "6px",
                      zIndex: 10,
                      background: this.state.showPelzLine ? "purple" : "#3e3e3e",
                      color: "white",
                      fontFamily: "'Barlow Condensed', sans-serif"
                    } : {
                        fontWeight: 600,
                        position: "absolute",
                        right: this.state.PelzLinePosition,
                        top: '3px',
                        right: "6px",
                        width: '80px',
                        zIndex: 10,
                        background: this.state.showPelzLine ? "purple" : "#3e3e3e",
                        color: "white",
                        fontFamily: "'Barlow Condensed', sans-serif"
                      }}
                  >
                    PelzLine<sup>TM</sup>
                  </button>


                  <img
                    id="arrowSlope"
                    width="0"
                    height="0"
                    src={LARGESLOPEARROW}
                    alt="arrowSlope"
                  />

                  <img
                    id="puttLength"
                    width="0"
                    height="0"
                    src={PUTTLENGTHTRIANGLE}
                    alt="puttLength"
                  />

                  <img
                    id="puttTime"
                    width="0"
                    height="0"
                    src={PUTTTIMETRIANGLE}
                    alt="puttTime"
                  />

                  <img
                    id="aimPoint"
                    width="0"
                    height="0"
                    src={AIMPOINT}
                    alt="aimPoint"
                  />

                  <img
                    id="whiteBall"
                    width="0"
                    height="0"
                    src={WHITEBALL}
                    alt="whiteBall"
                  />

                  <img
                    id="yellowBall"
                    width="0"
                    height="0"
                    src={YELLOWBALL}
                    alt="yellowBall"
                  />

                  <img
                    id="grassTexture"
                    width="0"
                    height="0"
                    src={GRASSTEXTURE}
                    alt="grassTexture"
                  />

                  <img id="hole" width="0" height="0" src={HOLE} alt="hole" />

                  <img
                    id="shooze"
                    width="0"
                    height="0"
                    src={SHOOZE}
                    alt="Sooze"
                  />
                  <img
                    id="shooze2"
                    width="0"
                    height="0"
                    src={SHOOZE2}
                    alt="Sooze"
                  />
                  <img
                    id="arrow1"
                    width="0"
                    height="0"
                    src={ARROW1}
                    alt="Sooze"
                  />
                  <img
                    id="arrow2"
                    width="0"
                    height="0"
                    src={ARROW2}
                    alt="Sooze"
                  />
                  <img
                    id="arrowleft1"
                    width="0"
                    height="0"
                    src={ARROWLEFT1}
                    alt="Sooze"
                  />
                  <img
                    id="arrowleft2"
                    width="0"
                    height="0"
                    src={ARROWLEFT1}
                    alt="Sooze"
                  />
                  <canvas id="canvas" />
                </div>
                {/* triangle html canvas end*/}

                {this.state.showSliders === false && (
                  <div className="vSliderBox">
                    <div className="vSlider vPLength">
                      <span class="slider-text-container">PuttLength<sup>TM</sup></span>
                      <span class="slider-value-container"> {this.calculateInFeetnInch(
                        this.state.PLength.currentValue
                      )}</span>
                      <span onClick={() =>
                        this.decreaseSliderValue("PLength")
                      } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                      <span onClick={() =>
                        this.increaseSliderValue("PLength")
                      } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                      <ReactBootstrapSlider
                        reversed={true}
                        value={this.state.PLength.currentValue}
                        change={e => this.changeSliderValue(e, "PLength")}
                        slideStop={e => this.changeSliderValue(e, "PLength")}
                        formatter={this.calculateInFeetnInch}
                        step={this.state.PLength.step}
                        max={this.state.PLength.max}
                        min={this.state.PLength.min}
                        orientation="vertical"
                      />
                    </div>

                    <div className="vSlider vPuttTime">
                      <span class="slider-text-container">PuttTime<sup>TM</sup></span>
                      <span class="slider-value-container"> {this.puttTimeReformater(this.calculatePuttTime(
                        this.state.PuttTime.currentValue
                      ))}</span>
                      <span onClick={() =>
                        this.decreaseSliderValue("PuttTime")
                      } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                      <span onClick={() =>
                        this.increaseSliderValue("PuttTime")
                      } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                      <ReactBootstrapSlider
                        value={this.state.PuttTime.currentValue}
                        change={e => this.changeSliderValue(e, "PuttTime")}
                        slideStop={e => this.changeSliderValue(e, "PuttTime")}
                        data-action={"PuttTime"}
                        name="PuttTime"
                        step={this.state.PuttTime.step}
                        max={this.state.PuttTime.max}
                        min={this.state.PuttTime.min}
                        orientation="vertical"
                        reversed={true}
                      />
                    </div>

                    <div className="vSlider vPASlope">
                      <span class="slider-text-container">PinAreaSlope<sup>TM</sup></span>
                      <span class="slider-value-container">{this.state.PASlope.currentValue}<span className="paslope_percentage">%</span></span>
                      <span onClick={() =>
                        this.decreaseSliderValue("PASlope")
                      } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                      <span onClick={() =>
                        this.increaseSliderValue("PASlope")
                      } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                      <ReactBootstrapSlider
                        value={this.state.PASlope.currentValue}
                        change={e => this.changeSliderValue(e, "PASlope")}
                        slideStop={e => this.changeSliderValue(e, "PASlope")}
                        step={this.state.PASlope.step}
                        max={this.state.PASlope.max}
                        min={this.state.PASlope.min}
                        orientation="vertical"
                        reversed={true}
                      />
                    </div>

                    <div className="vSlider vGreenSpeed">
                      <span class="slider-text-container">GreenSpeed</span>
                      <span class="slider-value-container">{this.calculateInFeetnInch(
                        this.state.GreenSpeed.currentValue
                      )}</span>
                      <span onClick={() =>
                        this.decreaseSliderValue("GreenSpeed")
                      } class="slider-minus-btn"><i class="fa fa-minus" aria-hidden="true"></i></span>
                      <span onClick={() =>
                        this.increaseSliderValue("GreenSpeed")
                      } class="slider-plus-btn"><i class="fa fa-plus" aria-hidden="true"></i></span>
                      <ReactBootstrapSlider
                        value={this.state.GreenSpeed.currentValue}
                        change={e => this.changeSliderValue(e, "GreenSpeed")}
                        slideStop={e => this.changeSliderValue(e, "GreenSpeed")}
                        formatter={this.calculateInFeetnInch}
                        step={this.state.GreenSpeed.step}
                        max={this.state.GreenSpeed.max}
                        min={this.state.GreenSpeed.min}
                        orientation="vertical"
                        reversed={true}
                      />
                    </div>
                  </div>
                )}

                <div
                  className="graphical-view-slider col-md-5 col-lg-5 col-sm-12"
                  id="graph-slides-wrapper"
                >

                  <div className="graph-slides" id="graph-slides">

                    {this.state.showSliders === true && <div

                      className="graph-input"
                    >
                      <ul className="g-slider putt-time">
                        <li>
                          <p>
                            <span className="g-head">
                              PuttTime<sup>TM</sup>
                            </span>
                            <span className="g-value">
                              {this.puttTimeReformater(this.calculatePuttTime(
                                this.state.PuttTime.currentValue
                              ))}
                            </span>

                            <ReactBootstrapSlider
                              value={this.state.PuttTime.currentValue}
                              change={e => this.changeSliderValue(e, "PuttTime")}
                              slideStop={e =>
                                this.changeSliderValue(e, "PuttTime")
                              }
                              data-action={"PuttTime"}
                              name="PuttTime"
                              step={this.state.PuttTime.step}
                              max={this.state.PuttTime.max}
                              min={this.state.PuttTime.min}
                              orientation="horizontal"
                            />

                            <span className="plus controlSlider">
                              <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.decreaseSliderValue("PuttTime")
                                }
                              />
                            </span>
                            <span className="minus controlSlider">
                              <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.increaseSliderValue("PuttTime")
                                }
                              />
                            </span>
                          </p>
                        </li>
                      </ul>

                      <ul className="g-slider putt-length">
                        <li>
                          <p>
                            <span className="g-head">
                              PuttLength<sup>TM</sup>
                            </span>
                            <span className="g-value">
                              {this.calculateInFeetnInch(
                                this.state.PLength.currentValue
                              )}
                            </span>

                            <ReactBootstrapSlider
                              value={this.state.PLength.currentValue}
                              change={e => this.changeSliderValue(e, "PLength")}
                              slideStop={e =>
                                this.changeSliderValue(e, "PLength")
                              }
                              formatter={this.calculateInFeetnInch}
                              step={this.state.PLength.step}
                              max={this.state.PLength.max}
                              min={this.state.PLength.min}
                              orientation="horizontal"
                            />
                            <span className="plus controlSlider">
                              <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.decreaseSliderValue("PLength")
                                }
                              />
                            </span>
                            <span className="minus controlSlider">
                              <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.increaseSliderValue("PLength")
                                }
                              />
                            </span>
                          </p>
                        </li>
                      </ul>

                      <ul className="g-slider pin-area">
                        <li>
                          <p>
                            <span className="g-head">
                              PinAreaSlope<sup>TM</sup>
                            </span>
                            <span className="g-value">
                              {this.state.PASlope.currentValue}%
                          </span>

                            <ReactBootstrapSlider
                              value={this.state.PASlope.currentValue}
                              change={e => this.changeSliderValue(e, "PASlope")}
                              slideStop={e =>
                                this.changeSliderValue(e, "PASlope")
                              }
                              step={this.state.PASlope.step}
                              max={this.state.PASlope.max}
                              min={this.state.PASlope.min}
                              orientation="horizontal"
                            />
                            <span className="plus controlSlider">
                              <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.decreaseSliderValue("PASlope")
                                }
                              />
                            </span>
                            <span className="minus controlSlider">
                              <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.increaseSliderValue("PASlope")
                                }
                              />
                            </span>
                          </p>
                        </li>
                      </ul>

                      <ul className="g-slider green-speed">
                        <li>
                          <p>
                            <span className="g-head">GreenSpeed</span>
                            <span className="g-value">
                              {this.calculateInFeetnInch(
                                this.state.GreenSpeed.currentValue
                              )}
                            </span>

                            <ReactBootstrapSlider
                              value={this.state.GreenSpeed.currentValue}
                              change={e =>
                                this.changeSliderValue(e, "GreenSpeed")
                              }
                              slideStop={e =>
                                this.changeSliderValue(e, "GreenSpeed")
                              }
                              formatter={this.calculateInFeetnInch}
                              step={this.state.GreenSpeed.step}
                              max={this.state.GreenSpeed.max}
                              min={this.state.GreenSpeed.min}
                              orientation="horizontal"
                            />

                            <span className="plus controlSlider">
                              <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.decreaseSliderValue("GreenSpeed")
                                }
                              />
                            </span>
                            <span className="minus controlSlider">
                              <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                onClick={() =>
                                  this.increaseSliderValue("GreenSpeed")
                                }
                              />
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>}





                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default Graph;



/**
   * @todo This method will helps draw image object onto the canvas and rotate the canvas image object on its axis
   * @param {object} ctx
   * @param {object} image
   * @param {float} x
   * @param {float} y
   * @param {float} w
   * @param {float} h
   * @param {float} degrees
   * @return {null} 
   */

function drawImage(ctx, image, x, y, w, h, degrees) {
  ctx.save();
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate((degrees * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
  ctx.drawImage(image, x, y, w, h);
  ctx.restore();
}
function drawImage2(ctx, image, x, y, w, h, degrees) {
  ctx.save();
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate((degrees * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
  ctx.drawImage(image, x, y, w, h);
  ctx.restore();
}


/**
   * @todo This method will helps detect the mobile devices
   * @return {boolean} 
   */

function detectmob() {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    // || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  else {
    return false;
  }
}

/**
   * @todo This method will helps detect the device such as Ipad
   * @return {boolean} 
   */

function detectIPad() {
  if (navigator.userAgent.match(/iPad/i)) {
    return true
  }
  return false
}



/**
   * @todo This method will helps rotate the canvas object on its axis
   * @param {object} ctx
   * @param {float} x
   * @param {float} y
   * @param {float} w
   * @param {float} h
   * @param {float} degrees
   * @return {null} 
   */

function rotateOnAxis(ctx, x, y, w, h, degrees) {
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate(((-1 * degrees) * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
}


function drawLabelAndRotate(ctx, text, x, y, w, h, degrees) {
  ctx.save();
  ctx.fillStyle = "white";
  ctx.font = "bold 20px 'Barlow Condensed', sans-serif";
  ctx.textAlign = 'center';
  ctx.translate(x + w / 2, y + h / 2);
  ctx.rotate(((-1 * degrees) * Math.PI) / 180.0);
  ctx.translate(-x - w / 2, -y - h / 2);
  ctx.fillText(text, x, y);
  ctx.restore();
}
function drawLabel(ctx, text, p1, p2, alignment, padding, addx, addy) {
  if (!alignment) alignment = 'center';
  if (!padding) padding = 0;

  var dx = p2.x - p1.x;
  var dy = p2.y - p1.y;
  var p, pad;
  if (alignment == 'center') {
    p = p1;
    pad = 1 / 2;
  } else {
    var left = alignment == 'left';
    p = left ? p1 : p2;
    pad = padding / Math.sqrt(dx * dx + dy * dy) * (left ? 1 : -1);
  }
  ctx.fillStyle = "white";
  ctx.font = "bold 20px 'Barlow Condensed', sans-serif";
  ctx.save();
  ctx.textAlign = alignment;
  ctx.translate((p.x + dx * pad) + addx, (p.y + dy * pad) + addy);
  ctx.rotate(Math.atan2(dy, dx));
  ctx.fillText(text, 0, 0 );
  ctx.restore();
}