import React, { Component } from 'react';
import GreenReading from '../../components/green_reading/green_reading'

class GreenReadingContainer extends Component {
  render() {
    return (
        <GreenReading />
    );
  }
}

export default GreenReadingContainer;
