import React, { Component } from 'react';
import { linkInit, API_URL } from '../../constants/config';
import axios from 'axios';
import NIDDLE from '../../assets/img/pin-clock.svg';
// import LOGO from '../../assets/img/pelz_golf_logo.gif';
import ROTATE from '../../assets/img/Rotate.png';
// import BACK_BUTTON from '../../assets/img/back-icon.png';
// import TOGGLE from '../../assets/img/toggle-icon.png'
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import $ from 'jquery';
import MediaQuery from 'react-responsive'

class GreenReading extends Component {
  constructor(props) {
    super(props);
    let calculatorValue = JSON.parse(localStorage.getItem('formData'));
    let copyParam = { GreenSpeed: 10, PASlope: 2, PLength: 6, PuttTime: '9:30' }
    if (calculatorValue != null) {
      console.log('calculatorValue', calculatorValue)
      copyParam = { GreenSpeed: calculatorValue.GreenSpeed, PASlope: calculatorValue.PASlope, PLength: calculatorValue.PLength, PuttTime: calculatorValue.PuttTime }
    }
    this.state = {
      formData: copyParam,
      Output: {
        PuttPower: 0,
        Break: 0
      },
      position: {
        leftText: 'Left to right',
        rightText: 'up hill'
      },
      deaultValue: [],
      showStatus: true
    }
    this.getDefaultValue();
  }

  /**
   * @todo This method will make api call and get the data used for calculation
   * @return {null}
   */

  getDefaultValue = () => {
    axios.get(`${API_URL}/public/solution`)
      .then((response) => {


        this.setState({
          ...this.state,
          deaultValue: response.data
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  componentDidMount = async () => {

    // window.addEventListener('orientationchange', function () {

    //   if (window.innerHeight > window.innerWidth) {
    //     document.getElementsByTagName('html')[0].style.transform = "rotate(-90deg)";
    //     document.getElementsByTagName('html')[0].style.transformOrigin = "left top";
    //     // document.getElementsByTagName('html')[0].style.width = "100vh";
    //     document.getElementsByTagName('html')[0].style.height = "100vw";

    //     document.getElementsByTagName('html')[0].style.overflowX = "hidden";
    //     document.getElementsByTagName('html')[0].style.position = "absolute";
    //     document.getElementsByTagName('html')[0].style.top = "385px";
    //     document.getElementsByTagName('html')[0].style.left = "0";
    //     document.getElementsByTagName('html')[0].style.webkitTextSizeAdjust = '100%'


    //   }
    //   else {
    //     document.getElementsByTagName('html')[0].style.transform = "none";
    //     document.getElementsByTagName('html')[0].style.transformOrigin = "unset";
    //     document.getElementsByTagName('html')[0].style.height = "100vh";
    //     document.getElementsByTagName('html')[0].style.overflowX = "hidden";
    //     document.getElementsByTagName('html')[0].style.position = "absolute";
    //     document.getElementsByTagName('html')[0].style.top = "unset";
    //     document.getElementsByTagName('html')[0].style.left = "0";
    //   }

    // });
    if (JSON.parse(localStorage.getItem('formData')) != null) {
      let attribute = this.state.formData.PuttTime
      let hours = attribute.split(':');
      let currentTime = "9:30";
      let currentHours = currentTime.split(':');
      let movedTime = 30;
      let currentMinutes = parseInt(currentHours[1]);
      movedTime = currentMinutes / 2;
      let calcHour = (hours[0] - currentHours[0]) * 30;
      if (parseInt(currentHours[0]) > parseInt(hours[0])) {
        calcHour = ((12 - parseInt(currentHours[0])) + parseInt(hours[0])) * 30;
      }
      calcHour = (calcHour - movedTime) + hours[1] / 2;
      $(".niddle").animate({ now: `+=${calcHour}` }, { step: function (now, fx) { $(this).css('transform', 'rotate(' + (now + 15) + 'deg)'); } });
    }

  }

  /**
   * @todo This method will handle clock inverse functionality
   * @param {Array} hours
   * @return {int}
   */

  clockInverse = (hours) => {
    // let hr = 12 - hours[0]; before

    let hr = hours[0] === 12 ? 12 : 12 - hours[0];


    let mint = hours[1];
    if (mint === 0) {
      hours[0] = hr;
    } else {
      hours[0] = hr - 1;
      hours[1] = 60 - hours[1];
    }
    //return (hours[0] + ":" + hours[1]);
    return hours;
  }

  /**
   * @todo This method will handle the downhill postions based on the calculations
   * @param {object} data
   * @return {object} 
   */

  getRoundDown = async (data) => {


    let greenSpeedDown = Math.floor(data.GreenSpeed);
    let slopeDown = Math.floor(data.PASlope);
    let lengthDown = 0;
    //=IF(P30<3, 2, 3*ROUNDDOWN(P30/3,0))
    if (data.PLength < 3) {
      lengthDown = 2
    } else {
      lengthDown = (3 * Math.floor(data.PLength / 3));
    }
    //=TIME(HOUR(Q30),0,0)
    let timeDown = '';
    let timeDownHour = 0;
    if (data.PuttTime !== '') {
      timeDown = data.PuttTime.split(":");
      if (parseInt(timeDown[0]) < 6 || (parseInt(timeDown[0]) === 12 && parseInt(timeDown[0]) > 0)) {
        let mirrorTime = await this.clockInverse(timeDown);
        timeDownHour = mirrorTime[0] + ":00";
      } else {
        timeDownHour = timeDown[0] + ":00";
      }
    }

    let downVal = { GreenSpeed: greenSpeedDown, PASlope: slopeDown, PLength: lengthDown, PuttTime: timeDownHour }
    return downVal;
  }

  /**
   * @todo This method will handle up position of the hill based on the calcultaion 
   * @param {object} data
   * @return {object}
   */
  getRoundUp = (data) => {
    let greenSpeedUp = Math.ceil(data.GreenSpeed)
    let slopeUp = 0;
    //=IF(O30<=4,ROUNDUP(O30,0), 4.5)
    if (data.PASlope <= 4) {
      slopeUp = Math.ceil(data.PASlope)
    } else {
      slopeUp = 4.5
    }

    let lengthUp = 0;
    let timeUpHour = 0;
    //=IF(P30<3, 3, 3*ROUNDUP(P30/3,0))
    if (data.PLength < 3) {
      lengthUp = 3
    } else {
      lengthUp = (3 * Math.ceil(data.PLength / 3));
    }

    //=IF(Q30=TIME(12,0,0), TIME(12,0,0), TIME(HOUR(Q30)+1,0,0))
    let dataPuttTime = data.PuttTime;
    if (dataPuttTime !== '') {
      let timeSplit = dataPuttTime.split(":");
      if (parseInt(timeSplit[0]) < 6 || (parseInt(timeSplit[0]) === 12 && parseInt(timeSplit[0]) > 0)) {
        timeSplit = this.clockInverse(timeSplit);
      }
      if (dataPuttTime === timeSplit[0]) {
        timeUpHour = timeSplit[0];
      } else {
        timeUpHour = (parseInt(timeSplit[0]) + 1).toString() + ":00";
      }
    }

    let upVal = { GreenSpeed: greenSpeedUp, PASlope: slopeUp, PLength: lengthUp, PuttTime: timeUpHour }
    return upVal;
  }


  /**
   * @todo This method will handle the Weighting Down postions based on the calculations
   * @param {object} roundDown 
   * @param {object} roundUp
   * @return {object} 
   */
  getWeightingDown = (roundDown, roundUp) => {
    let data = this.state.formData;
    let weightGreenSpeedDown = 0;
    //=IF(N30=N31, 100%, (N32-N30)/(N32-N31))
    if (data.GreenSpeed === roundDown.GreenSpeed) {
      weightGreenSpeedDown = 100;
    } else {
      weightGreenSpeedDown = (((roundUp.GreenSpeed - data.GreenSpeed) / (roundUp.GreenSpeed - roundDown.GreenSpeed)) * 100).toFixed(1);
    }

    let weightSlopeDown = 0;
    //=IF(O30=O31, 100%, (O32-O30)/(O32-O31))
    if (data.PASlope === roundDown.PASlope) {
      weightSlopeDown = 100;
    } else {
      weightSlopeDown = (((roundUp.PASlope - data.PASlope) / (roundUp.PASlope - roundDown.PASlope)) * 100).toFixed(1);
    }

    let weightLengthDown = 0;
    //=IF(P30=P31, 100%, (P32-P30)/(P32-P31))
    if (data.PLength === roundDown.PLength) {
      weightLengthDown = 100;
    } else {
      weightLengthDown = (((roundUp.PLength - data.PLength) / (roundUp.PLength - roundDown.PLength)) * 100).toFixed(1);
    }

    let weightTimehDown = 0;
    //=IF(Q30=Q31, 100%, (Q32-Q30)/(Q32-Q31))

    let dataPuttTime = (data.PuttTime);
    let timeSplit = dataPuttTime.split(":");
    if (parseInt(timeSplit[0]) < 6 || (parseInt(timeSplit[0]) === 12 && parseInt(timeSplit[0]) > 0)) {
      let mirrorWeightingDown = this.clockInverse(timeSplit);
      dataPuttTime = mirrorWeightingDown[0] + ":" + mirrorWeightingDown[1];
    }
    let firstTime = this.getSubtractionTime(roundUp.PuttTime, dataPuttTime);
    let secondTime = this.getSubtractionTime(roundUp.PuttTime, roundDown.PuttTime);

    if (dataPuttTime === roundDown.PuttTime) {
      weightTimehDown = 100;
    } else {
      weightTimehDown = (firstTime / secondTime) * 100;
    }

    let weightDown = { GreenSpeed: weightGreenSpeedDown, PASlope: weightSlopeDown, PLength: parseFloat(weightLengthDown), PuttTime: weightTimehDown }
    return weightDown;
  }

  /**
   * @todo This method will handle time conversions 
   * @param {string} startTime
   * @param {string} endTime
   * @return {string}
   */

  getSubtractionTime = (startTime, endTime) => {
    let sTime = startTime.split(':');
    let eTime = endTime.split(':');
    let fMinutes = 0;
    // let fHours = 0;
    if (sTime[1] > eTime[1] && sTime[0] > eTime[0]) {
      fMinutes = parseInt(sTime[1]) - parseInt(eTime[1]);
      // fHours = parseInt(sTime[0]) - parseInt(eTime[0]);
    } else {
      fMinutes = (parseInt(sTime[1]) + 60) - parseInt(eTime[1]);
      // fHours = (parseInt(sTime[0]) - 1) - parseInt(eTime[0]);
    }
    return fMinutes;
  }

  /**
   * @todo This method will handle up position using calculation
   * @param {object} weightingDown
   * @return {object}
   */
  getWeightingUp = (weightingDown) => {
    // let data = this.state.formData;
    //=1-N33
    let weightGreenSpeedUp = (100 - weightingDown.GreenSpeed);
    //=1-O33
    let weightSlopeUp = (100 - weightingDown.PASlope);
    //=1-P33
    let weightLengthUp = (100 - weightingDown.PLength);
    //=1-Q33
    let weightTimehUp = (100 - weightingDown.PuttTime);

    let weightUp = { GreenSpeed: weightGreenSpeedUp, PASlope: weightSlopeUp, PLength: parseFloat(weightLengthUp), PuttTime: weightTimehUp }
    return weightUp;
  }

  /**
     * @todo This method will handle all  inputchanges , set state and call another method
     * @param {object} e
     * @param {string} sliderName
     * @return {null}
     */
  handleChnage = (event) => {
    let attribute = event.target.getAttribute('data-state');
    let action = event.target.getAttribute('data-action');
    let value = parseFloat(event.target.value);
    // let self = this;
    if (attribute === 'add') {
      value = this.state.formData[action] + 0.5;
    }
    this.setDefaultValueToGraph(action, value);
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        [action]: value
      },
      showStatus: true
    });

    this.getDefaultValue();
  }


  /**
   * @todo This method will handle putt time processing
   * @param {string} attribute
   * @return {null}
   */

  handlePuttTime = (attribute) => {


    let hours = attribute.split(':');
    let currentTime = this.state.formData.PuttTime;
    let currentHours = currentTime.split(':');
    let movedTime = 30;
    let currentMinutes = parseInt(currentHours[1]);
    movedTime = currentMinutes / 2;
    let calcHour = (hours[0] - currentHours[0]) * 30;
    if (parseInt(currentHours[0]) > parseInt(hours[0])) {
      calcHour = ((12 - parseInt(currentHours[0])) + parseInt(hours[0])) * 30;
    }
    calcHour = calcHour - movedTime;

    $(".niddle").animate({ now: `+=${calcHour}` }, { step: function (now, fx) { $(this).css('transform', 'rotate(' + (now + 15) + 'deg)'); } });
    this.setDefaultValueToGraph('PuttTime', attribute);
    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        PuttTime: attribute
      },
      showStatus: true
    });
  }

  /**
   * @todo This method will get default value to graph to local storage
   * @param {string} name
   * @param {string} value
   * @return {undefined}
   */

  setDefaultValueToGraph = (name, value) => {
    let setLocalValue = { GreenSpeed: this.state.formData.GreenSpeed, PASlope: this.state.formData.PASlope, PLength: this.state.formData.PLength, PuttTime: this.state.formData.PuttTime }
    setLocalValue[name] = value
    localStorage.setItem('formData', JSON.stringify(setLocalValue));
  }


  /**
  * @todo This method will handle add fifteen minutes functionality on +15 button clicked
  * @return {null} 
  */

  addFifteenMinutes = () => {
    let currentTime = this.state.formData.PuttTime;
    let getTime = currentTime.split(':');
    let hours = getTime[0];
    let minutes = getTime[1];
    $(".niddle").animate({ now: '+=7.5' }, { duration: 50, step: function (now, fx) { $(this).css('transform', 'rotate(' + (now + 15) + 'deg)'); } });
    switch (minutes) {
      case "00":
        minutes = '15';
        break;
      case "15":
        minutes = '30';
        break;
      case "30":
        minutes = '45';
        break;
      case "45":
        minutes = '00';
        if (hours === '12') {
          hours = '1';
        } else {
          hours = parseInt(hours);
          hours += 1;
        }
        break;
      default:
        break;

    }
    let changedTime = hours.toString() + ":" + minutes;
    if (hours.toString() == '12')
      hours = 0;
    let chnagedTime = hours.toString() + ":" + minutes;
    this.setDefaultValueToGraph('PuttTime', chnagedTime);
    this.setState({
      ...this.state,
      showStatus: true,
      formData: {
        ...this.state.formData,
        PuttTime: changedTime
      }
    });
  }

  /**
   * @todo This method will handle fifteen minutes minus functionality on -15 button clicked
   * @return {null} 
   */

  subtractFifteenMinutes = () => {
    let currentTime = this.state.formData.PuttTime;
    let getTime = currentTime.split(':');
    let hours = getTime[0];
    let minutes = getTime[1];
    $(".niddle").animate({ now: '-=7.5' }, { duration: 50, step: function (now, fx) { $(this).css('transform', 'rotate(' + (now + 15) + 'deg)'); } });

    switch (minutes) {
      case "00":
        minutes = '45';
        if (hours === '1') {
          hours = '12';
        } else {
          hours = parseInt(hours);
          hours -= 1;
        }
        break;
      case "15":
        minutes = '00';
        break;
      case "30":
        minutes = '15';
        break;
      case "45":
        minutes = '30';
        break;
      default:
        break;
    }
    let changedTime = hours.toString() + ":" + minutes;
    if (hours.toString() == '12')
      hours = 0;
    let chnagedTime = hours.toString() + ":" + minutes;
    this.setDefaultValueToGraph('PuttTime', chnagedTime);
    this.setState({
      ...this.state,
      showStatus: true,
      formData: {
        ...this.state.formData,
        PuttTime: changedTime
      }
    });
  }


  /**
   * @todo This method will handle processing of the Greading solutions 
   * @param {object} roundDown
   * @param {object} roundUp
   * @param {object} weightingTable
   * @return {Array} 
   */


  getGReadingSolution = async (roundDown, roundUp, weightingTable) => {


    let solutionArr = [];
    for (let i = 1; i <= 16; i++) {
      let gSpeed = 0;
      let pSlope = 0;
      let pLength = 0;
      let pTime = 0;
      let degree = 0;
      if (i <= 4 || (i >= 9 && i <= 12)) {
        gSpeed = roundDown.GreenSpeed;
      } else if ((i > 4 && i <= 8) || (i >= 13 && i <= 16)) {
        gSpeed = roundUp.GreenSpeed;
      }

      if (i <= 2 || (i > 4 && i <= 6) || (i > 8 && i <= 10) || (i > 12 && i <= 14)) {
        pSlope = roundDown.PASlope;
      } else if ((i > 2 && i <= 4) || (i > 6 && i <= 8) || (i > 10 && i <= 12) || (i > 14 && i <= 16)) {
        pSlope = roundUp.PASlope;
      }

      if (i % 2 === 0) {
        pLength = roundUp.PLength;
      } else {
        pLength = roundDown.PLength;
      }

      if (i <= 8) {
        pTime = roundDown.PuttTime;
      } else if (i > 8) {
        pTime = roundUp.PuttTime;
      }


      //=180-(12-HOUR(Q36))*30
      if (pTime !== '') {
        let getTime = pTime.split(":");
        degree = 180 - (12 - getTime[0]) * 30;
      }
      let solution = {
        "GreenSpeed": gSpeed,
        "PASlope": pSlope,
        "PLength": pLength,
        "PuttTime": pTime,
        "degree": degree
      };

      let rawData = this.getFilteredSolution(solution);


      let currentElement = {}
      if (rawData !== undefined) {
        currentElement = {
          "solution": solution,
          "raw_data": {
            "PuttPower": parseFloat(rawData.PuttPower),
            "Break": parseFloat(rawData.Break)
          },
          "weighted": {
            "PuttPower": (parseFloat(rawData.PuttPower) * weightingTable[i - 1].weight) / 100,
            "Break": (parseFloat(rawData.Break) * weightingTable[i - 1].weight) / 100
          }
        }
        solutionArr.push(currentElement);
      }
    }

    return solutionArr;
  }

  /**
   * @todo This method will handle filtration of the solutions 
   * @param {object} solution
   * @return {object} 
   */


  getFilteredSolution = (solution) => {
    var status = { PuttPower: 0, Break: 0.00 };
    let totalSolution = this.state.deaultValue;
    totalSolution.forEach((item, key) => {
      if (item.greenSpeed == solution.GreenSpeed && item.pASlope == solution.PASlope && item.puttLength == solution.PLength && item.puttTime === solution.PuttTime && item.puttAngle == solution.degree) {
        status = { PuttPower: item.puttPower, Break: item.break };
        return false;
      }
    })
    return status;
  }

  /**
   * @todo This method will handle processing of the weighting solutions 
   * @param {Array} weightingDown
   * @param {Array} weightingUp
   * @return {Array} 
   */

  getWeightingTable = (weightingDown, weightingUp) => {
    let weight_table = [
      {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingDown.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      },
      {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingUp.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      },
      {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingDown.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      },
      {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingUp.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      },
      {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingDown.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      },
      {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingUp.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      },
      {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingDown.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      },
      {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingDown.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingUp.PLength / 100) * (weightingDown.PuttTime / 100)) * 100
      }, {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingDown.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      },
      {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingUp.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      },
      {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingDown.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      },
      {
        "d": weightingDown.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingDown.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingUp.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      }, {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingDown.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      },
      {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingDown.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingDown.PASlope / 100) * (weightingUp.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      },
      {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingDown.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingDown.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      },
      {
        "d": weightingUp.GreenSpeed,
        "PASlope": weightingUp.PASlope,
        "th": weightingUp.PLength,
        "PuttTime": weightingUp.PuttTime,
        "weight": ((weightingUp.GreenSpeed / 100) * (weightingUp.PASlope / 100) * (weightingUp.PLength / 100) * (weightingUp.PuttTime / 100)) * 100
      }
    ]
    return weight_table;
  }


  /**
  * @todo This method will handle processing of the calculation of the values
  * @return {null} 
  */

  getCalculateValue = async () => {
    let roundDown = await this.getRoundDown(this.state.formData);
    let roundUp = await this.getRoundUp(this.state.formData);
    let weightingDown = await this.getWeightingDown(roundDown, roundUp);
    let weightingUp = await this.getWeightingUp(weightingDown);
    let weightingTable = await this.getWeightingTable(weightingDown, weightingUp);


    let solution = await this.getGReadingSolution(roundDown, roundUp, weightingTable);
    if (this.state.showStatus) {
      let totalPuttPoer = 0;
      let totalBreak = 0;
      for (let i = 0; i < solution.length; i++) {
        totalPuttPoer = totalPuttPoer + parseFloat((solution[i].weighted.PuttPower).toFixed(3));
        totalBreak = totalBreak + parseFloat((solution[i].weighted.Break).toFixed(3));
      }

      //=IF(INT(V68/12)>0, INT(V68/12) & " ft " & ROUND(MOD(V68,12),1) & " in", ROUND(MOD(V68,12),1) & " in")
      // let calBreak = String(totalBreak % 12);
      if (totalBreak > 12) {
        //totalBreak = parseInt(totalBreak / 12) + ' ft ' + (totalBreak % 12).toFixed(1) + ' in';
        let totalBreakFt = parseInt(totalBreak / 12);
        let totalBreakInch = (totalBreak % 12).toFixed(1);
        totalBreak = { totalBreakFt: totalBreakFt, totalBreakInch: totalBreakInch }
      } else {
        //totalBreak = (totalBreak % 12).toFixed(1) + ' in';
        let totalBreakInch = (totalBreak % 12).toFixed(1);
        totalBreak = { totalBreakFt: '', totalBreakInch: totalBreakInch }
      }


      this.setState({
        ...this.state,
        Output: {
          //=10*((U68-2.5-0.84)/10.54)
          PuttPower: (10 * ((totalPuttPoer - 2.5 - 0.84) / 10.54)).toFixed(1),
          Break: totalBreak
        },
        showStatus: false
      });
    }
  }

  /**
   * @todo This method will handle mirrored data based on the Putt time
   * @return {object} 
   */

  getClockMirrorData = () => {
    let currentTime = (this.state.formData.PuttTime).split(':');
    let timeHours = parseInt(currentTime[0]);
    let timeMinutes = parseInt(currentTime[1]);
    let clockMirror = { positionText: '', hillText: '' }

    if ((timeHours === 12) || (timeHours >= 1 && timeHours < 6)) {
      clockMirror.positionText = 'Right to Left';
    } else {
      clockMirror.positionText = 'Left to Right';
    }

    if ((timeHours >= 10 && timeHours <= 12) || (timeHours === 1 || (timeHours === 2 && timeMinutes === 0))) {
      clockMirror.hillText = 'Downhill';
    } else if ((timeHours >= 4 && timeHours <= 7) || (timeHours === 8 && timeMinutes === 0)) {
      clockMirror.hillText = 'Uphill';
    } else if (((timeHours === 2 && timeMinutes >= 15) || (timeHours === 3 && timeMinutes <= 45)) || (timeHours === 8 && timeMinutes >= 15) || timeHours < 10) {
      clockMirror.hillText = 'Sidehill';
    }
    return clockMirror;
  }
  locked = (issuccess) => {
    console.log('what happened', issuccess)
  }
  render() {
    let puttTimeStatus = true;
    if (this.state.formData.GreenSpeed > 0 && this.state.formData.PLength > 0 && this.state.formData.PuttTime !== '0:00' && this.state.showStatus) {
      if ((this.state.deaultValue).length > 0) {
        this.getCalculateValue();
      }
    }
    let hoursTime = parseInt((this.state.formData.PuttTime).split(":")[0]);
    let minutesTime = parseInt((this.state.formData.PuttTime).split(":")[1]);
    let PLengthActive = (Number.isInteger(this.state.formData.PLength)) ? 'remove-selected-point' : 'active';
    let additionalDisable = (this.state.formData.PLength === 12) ? true : false;
    let GreenSpeedActive = (Number.isInteger(this.state.formData.GreenSpeed)) ? 'remove-selected-point' : 'active';
    let additionalDisableGreen = (this.state.formData.GreenSpeed === 15) ? true : false;
    let lengthRemoveHover = (this.state.formData.PLength === 12) ? 'remove-hover' : '';
    let greenSpeedRemoveHover = (this.state.formData.GreenSpeed === 15) ? 'remove-hover' : '';
    let clockMirror = this.getClockMirrorData();

    if ((hoursTime === 3 && minutesTime >= 15) || (hoursTime === 4) || (hoursTime === 5) || (hoursTime === 6) || (hoursTime === 7) || (hoursTime === 8 && minutesTime <= 45)) {
      puttTimeStatus = false;
    }


    return (
      <React.Fragment>
        <MediaQuery maxWidth={870}>
          <DeviceOrientation lockOrientation={'portrait'}>
            <Orientation orientation='portrait' alwaysRender={false}>
              <React.Fragment>
                <div className="main-container">
                  <div id="putt-whisperer" className="clearfix">
                    <div className="putt-watch-panel">
                      <div className="col-xs-5 col-lg-2 putt">
                        <div className="break putt-box">
                          <h3>PuttBreak<span>TM</span></h3>
                          <h1>
                            {/* {(this.state.Output.Break.totalBreakFt != '') && (this.state.Output.Break.totalBreakFt)}
                    {(this.state.Output.Break.totalBreakFt != '') && <span className="small-size"> ft </span>}
                    { Math.floor(this.state.Output.Break.totalBreakInch) } <span className="small-size"> in</span> */}
                            {` ${this.state.Output.Break.totalBreakFt ? this.state.Output.Break.totalBreakFt + "'" : ''} ${this.state.Output.Break.totalBreakInch ? Math.floor(this.state.Output.Break.totalBreakInch) + '"' : ''} `}
                          </h1>
                          <p className="left-to-right-calc">{clockMirror.positionText} {clockMirror.hillText}</p>
                        </div>
                        <div className="break putt-box">
                          <h3>PuttPower<span>TM</span></h3>
                          <h1 >{(this.state.Output.PuttPower > 0) ? this.state.Output.PuttPower : 0}<span className="small-size"> F</span></h1>
                        </div>
                      </div>
                      <div className="col-xs-7 col-lg-5 set-angles">
                        <div className="clock">
                          <div className="clock-watch">

                            <div className="putttime putttime-top">
                              {!puttTimeStatus && <h1>{this.state.formData.PuttTime}</h1>}
                            </div>
                            <div className="pin">
                              <img className="niddle" src={NIDDLE} alt="" />
                            </div>
                            <div className="clock-numbers">
                              <ul>
                                <li data-state={1} onClick={() => this.handlePuttTime('1:00')} className={"number one" + ((hoursTime === 1) ? " clock-active " : "")}><span>1</span></li>
                                <li data-state={2} onClick={() => this.handlePuttTime('2:00')} className={"number two" + ((hoursTime === 2) ? " clock-active " : "")}>2</li>
                                <li data-state={3} onClick={() => this.handlePuttTime('3:00')} className={"number three" + ((hoursTime === 3) ? " clock-active " : "")}>3</li>
                                <li data-state={4} onClick={() => this.handlePuttTime('4:00')} className={"number four" + ((hoursTime === 4) ? " clock-active " : "")}>4</li>
                                <li data-state={5} onClick={() => this.handlePuttTime('5:00')} className={"number five" + ((hoursTime === 5) ? " clock-active " : "")}>5</li>
                                <li data-state={6} onClick={() => this.handlePuttTime('6:00')} className={"number six" + ((hoursTime === 6) ? " clock-active " : "")}>6</li>
                                <li data-state={7} onClick={() => this.handlePuttTime('7:00')} className={"number seven" + ((hoursTime === 7) ? " clock-active " : "")}>7</li>
                                <li data-state={8} onClick={() => this.handlePuttTime('8:00')} className={"number eight" + ((hoursTime === 8) ? " clock-active " : "")}>8</li>
                                <li data-state={9} onClick={() => this.handlePuttTime('9:00')} className={"number nine" + ((hoursTime === 9) ? " clock-active " : "")}>9</li>
                                <li data-state={10} onClick={() => this.handlePuttTime('10:00')} className={"number ten" + ((hoursTime === 10) ? " clock-active " : "")}>10</li>
                                <li data-state={11} onClick={() => this.handlePuttTime('11:00')} className={"number eleven" + ((hoursTime === 11) ? " clock-active " : "")}>11</li>
                                <li data-state={12} onClick={() => this.handlePuttTime('12:00')} className={"number twelve" + ((hoursTime === 12) ? " clock-active " : "")}>12</li>
                              </ul>
                            </div>
                            <div className="plus-minus-angle col-sm-12 clearfix">
                              <button className="btn btn-circle pull-left dec-min" onClick={this.subtractFifteenMinutes}>-15</button>
                              <button className="btn btn-circle pull-right inc-min" onClick={this.addFifteenMinutes}>+15</button>
                            </div>
                          </div>

                          <div className="putttime">
                            <h3>PuttTime<span>TM</span></h3>
                            {puttTimeStatus && <h1>{this.state.formData.PuttTime}</h1>}
                          </div>
                        </div>

                      </div>

                    </div>
                    <section className="putt-pin-green D-putt-green col-xs-12 col-lg-5">
                      <h3 className={'bold-font puttlength-calc'} >PuttLength<span>TM</span> (Feet)</h3>
                      <div className="putt putt-box D-putt clearfix">
                        <div className="value-add">
                          <span>{this.state.formData.PLength}'</span>
                        </div>
                        <div className="select-value">
                          <div>
                            <input type="button" data-state={2} data-action={"PLength"} value="2" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 2 && this.state.formData.PLength < 3) ? "active " : "")} />
                            <input type="button" data-state={3} data-action={"PLength"} value="3" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 3 && this.state.formData.PLength < 4) ? "active " : "")} />
                            <input type="button" data-state={4} data-action={"PLength"} value="4" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 4 && this.state.formData.PLength < 5) ? "active " : "")} />
                            <input type="button" data-state={5} data-action={"PLength"} value="5" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 5 && this.state.formData.PLength < 6) ? "active " : "")} />
                            <input type="button" data-state={6} data-action={"PLength"} value="6" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 6 && this.state.formData.PLength < 7) ? "active " : "")} />
                            <input type="button" data-state={7} data-action={"PLength"} value="7" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 7 && this.state.formData.PLength < 8) ? "active " : "")} />
                          </div>
                          <div>
                            <input type="button" data-state={8} data-action={"PLength"} value="8" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 8 && this.state.formData.PLength < 9) ? "active " : "")} />
                            <input type="button" data-state={9} data-action={"PLength"} value="9" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 9 && this.state.formData.PLength < 10) ? "active " : "")} />
                            <input type="button" data-state={10} data-action={"PLength"} value="10" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 10 && this.state.formData.PLength < 11) ? "active " : "")} />
                            <input type="button" data-state={11} data-action={"PLength"} value="11" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 11 && this.state.formData.PLength < 12) ? "active " : "")} />
                            <input type="button" data-state={12} data-action={"PLength"} value="12" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength === 12) ? "active " : "")} />
                            <input type="button" data-state='add' data-action={"PLength"} value="+.5" onClick={this.handleChnage} disabled={additionalDisable} className={"btn btn-circle btn-value add-extra " + PLengthActive + ' ' + lengthRemoveHover} />
                          </div>
                        </div>
                      </div>

                      <h3 className={'bold-font pinareaslope-calc'}>PinAreaSlope<span>TM</span> (%)</h3>
                      <div className="putt putt-box area D-putt clearfix">
                        <div className="paSlopeValue">
                          {/* <div className="value-add"> */}
                          <span>{this.state.formData.PASlope}</span>
                          <span className={'percentage'}>%</span>
                        </div>
                        <div className="select-value">
                          <div>
                            <input type="button" value={0} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 0) ? "active " : "")} />
                            <input type="button" value={0.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 0.5) ? "active " : "")} />
                            <input type="button" value={1} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 1) ? "active " : "")} />
                            <input type="button" value={1.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 1.5) ? "active " : "")} />
                            <input type="button" value={2} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 2) ? "active " : "")} />
                          </div>
                          <div>

                            <input type="button" value={2.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 2.5) ? "active " : "")} />
                            <input type="button" value={3} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 3) ? "active " : "")} />
                            <input type="button" value={3.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 3.5) ? "active " : "")} />
                            <input type="button" value={4} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 4) ? "active " : "")} />
                            <input type="button" value={4.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 4.5) ? "active " : "")} />
                          </div>
                        </div>
                      </div>
                      <h3 className={'bold-font greenspeed-calc'}>GreenSpeed<span></span> (Feet)</h3>
                      <div className="putt putt-box area D-putt green clearfix">
                        <div className="value-add">
                          <span>{this.state.formData.GreenSpeed}'</span>
                        </div>
                        <div className="select-value">
                          <div>
                            <input type="button" data-state={7} data-action={"GreenSpeed"} onClick={this.handleChnage} value="7" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 7 && this.state.formData.GreenSpeed < 8) ? "active " : "")} />
                            <input type="button" data-state={8} data-action={"GreenSpeed"} onClick={this.handleChnage} value="8" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 8 && this.state.formData.GreenSpeed < 9) ? "active " : "")} />
                            <input type="button" data-state={9} data-action={"GreenSpeed"} onClick={this.handleChnage} value="9" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 9 && this.state.formData.GreenSpeed < 10) ? "active " : "")} />
                            <input type="button" data-state={10} data-action={"GreenSpeed"} onClick={this.handleChnage} value="10" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 10 && this.state.formData.GreenSpeed < 11) ? "active " : "")} />
                            <input type="button" data-state={11} data-action={"GreenSpeed"} onClick={this.handleChnage} value="11" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 11 && this.state.formData.GreenSpeed < 12) ? "active " : "")} />
                          </div>
                          <div>
                            <input type="button" data-state={12} data-action={"GreenSpeed"} onClick={this.handleChnage} value="12" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 12 && this.state.formData.GreenSpeed < 13) ? "active " : "")} />
                            <input type="button" data-state={13} data-action={"GreenSpeed"} onClick={this.handleChnage} value="13" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 13 && this.state.formData.GreenSpeed < 14) ? "active " : "")} />
                            <input type="button" data-state={14} data-action={"GreenSpeed"} onClick={this.handleChnage} value="14" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 14 && this.state.formData.GreenSpeed < 15) ? "active " : "")} />
                            <input type="button" data-state={15} data-action={"GreenSpeed"} onClick={this.handleChnage} value="15" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed === 15) ? "active " : "")} />
                            <input type="button" data-state={"add"} data-action={"GreenSpeed"} onClick={this.handleChnage} value="+.5" disabled={additionalDisableGreen} className={"btn btn-circle btn-value add-extra " + GreenSpeedActive + ' ' + greenSpeedRemoveHover} />
                          </div>
                        </div>
                      </div>
                    </section>

                  </div>
                  <div className="clearfix"></div>
                  <div className={'see-putt'}><a href={linkInit + "graph"} className="btn btn-primary">See My PuttPath!<sup>TM</sup></a></div>

                </div>
              </React.Fragment>
            </Orientation>
            <Orientation orientation='landscape' alwaysRender={false}>
              <React.Fragment>
                <div className="main-container rotateImagecontainer">
                  <img src={ROTATE} alt="" className="rotateImage" />
                </div>
              </React.Fragment>
            </Orientation>
          </DeviceOrientation>
        </MediaQuery>
        <MediaQuery minWidth={871}>

          <div className="main-container">
            <div id="putt-whisperer" className="clearfix">
              <div className="putt-watch-panel">
                <div className="col-xs-5 col-lg-2 putt">
                  <div className="break putt-box">
                    <h3>PuttBreak<span>TM</span></h3>
                    <h1>
                      {/* {(this.state.Output.Break.totalBreakFt != '') && (this.state.Output.Break.totalBreakFt)}
                    {(this.state.Output.Break.totalBreakFt != '') && <span className="small-size"> ft </span>}
                    { Math.floor(this.state.Output.Break.totalBreakInch) } <span className="small-size"> in</span> */}
                      {` ${this.state.Output.Break.totalBreakFt ? this.state.Output.Break.totalBreakFt + "'" : ''} ${this.state.Output.Break.totalBreakInch ? Math.floor(this.state.Output.Break.totalBreakInch) + '"' : ''} `}
                    </h1>
                    <p className="left-to-right-calc">{clockMirror.positionText} {clockMirror.hillText}</p>
                  </div>
                  <div className="break putt-box">
                    <h3>PuttPower<span>TM</span></h3>
                    <h1 >{(this.state.Output.PuttPower > 0) ? this.state.Output.PuttPower : 0}<span className="small-size"> F</span></h1>
                  </div>
                </div>
                <div className="col-xs-7 col-lg-5 set-angles">
                  <div className="clock">
                    <div className="clock-watch">

                      <div className="putttime putttime-top">
                        {!puttTimeStatus && <h1>{this.state.formData.PuttTime}</h1>}
                      </div>
                      <div className="pin">
                        <img className="niddle" src={NIDDLE} alt="" />
                      </div>
                      <div className="clock-numbers">
                        <ul>
                          <li data-state={1} onClick={() => this.handlePuttTime('1:00')} className={"number one" + ((hoursTime === 1) ? " clock-active " : "")}><span>1</span></li>
                          <li data-state={2} onClick={() => this.handlePuttTime('2:00')} className={"number two" + ((hoursTime === 2) ? " clock-active " : "")}>2</li>
                          <li data-state={3} onClick={() => this.handlePuttTime('3:00')} className={"number three" + ((hoursTime === 3) ? " clock-active " : "")}>3</li>
                          <li data-state={4} onClick={() => this.handlePuttTime('4:00')} className={"number four" + ((hoursTime === 4) ? " clock-active " : "")}>4</li>
                          <li data-state={5} onClick={() => this.handlePuttTime('5:00')} className={"number five" + ((hoursTime === 5) ? " clock-active " : "")}>5</li>
                          <li data-state={6} onClick={() => this.handlePuttTime('6:00')} className={"number six" + ((hoursTime === 6) ? " clock-active " : "")}>6</li>
                          <li data-state={7} onClick={() => this.handlePuttTime('7:00')} className={"number seven" + ((hoursTime === 7) ? " clock-active " : "")}>7</li>
                          <li data-state={8} onClick={() => this.handlePuttTime('8:00')} className={"number eight" + ((hoursTime === 8) ? " clock-active " : "")}>8</li>
                          <li data-state={9} onClick={() => this.handlePuttTime('9:00')} className={"number nine" + ((hoursTime === 9) ? " clock-active " : "")}>9</li>
                          <li data-state={10} onClick={() => this.handlePuttTime('10:00')} className={"number ten" + ((hoursTime === 10) ? " clock-active " : "")}>10</li>
                          <li data-state={11} onClick={() => this.handlePuttTime('11:00')} className={"number eleven" + ((hoursTime === 11) ? " clock-active " : "")}>11</li>
                          <li data-state={12} onClick={() => this.handlePuttTime('12:00')} className={"number twelve" + ((hoursTime === 12) ? " clock-active " : "")}>12</li>
                        </ul>
                      </div>
                      <div className="plus-minus-angle col-sm-12 clearfix">
                        <button className="btn btn-circle pull-left dec-min" onClick={this.subtractFifteenMinutes}>-15</button>
                        <button className="btn btn-circle pull-right inc-min" onClick={this.addFifteenMinutes}>+15</button>
                      </div>
                    </div>

                    <div className="putttime">
                      <h3>PuttTime<span>TM</span></h3>
                      {puttTimeStatus && <h1>{this.state.formData.PuttTime}</h1>}
                    </div>
                  </div>

                </div>

              </div>
              <section className="putt-pin-green D-putt-green col-xs-12 col-lg-5">
                <h3 className={'bold-font puttlength-calc'} >PuttLength<span>TM</span> (Feet)</h3>
                <div className="putt putt-box D-putt clearfix">
                  <div className="value-add">
                    <span>{this.state.formData.PLength}'</span>
                  </div>
                  <div className="select-value">
                    <div>
                      <input type="button" data-state={2} data-action={"PLength"} value="2" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 2 && this.state.formData.PLength < 3) ? "active " : "")} />
                      <input type="button" data-state={3} data-action={"PLength"} value="3" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 3 && this.state.formData.PLength < 4) ? "active " : "")} />
                      <input type="button" data-state={4} data-action={"PLength"} value="4" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 4 && this.state.formData.PLength < 5) ? "active " : "")} />
                      <input type="button" data-state={5} data-action={"PLength"} value="5" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 5 && this.state.formData.PLength < 6) ? "active " : "")} />
                      <input type="button" data-state={6} data-action={"PLength"} value="6" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 6 && this.state.formData.PLength < 7) ? "active " : "")} />
                      <input type="button" data-state={7} data-action={"PLength"} value="7" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 7 && this.state.formData.PLength < 8) ? "active " : "")} />
                    </div>
                    <div>
                      <input type="button" data-state={8} data-action={"PLength"} value="8" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 8 && this.state.formData.PLength < 9) ? "active " : "")} />
                      <input type="button" data-state={9} data-action={"PLength"} value="9" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 9 && this.state.formData.PLength < 10) ? "active " : "")} />
                      <input type="button" data-state={10} data-action={"PLength"} value="10" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 10 && this.state.formData.PLength < 11) ? "active " : "")} />
                      <input type="button" data-state={11} data-action={"PLength"} value="11" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength >= 11 && this.state.formData.PLength < 12) ? "active " : "")} />
                      <input type="button" data-state={12} data-action={"PLength"} value="12" onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PLength === 12) ? "active " : "")} />
                      <input type="button" data-state='add' data-action={"PLength"} value="+.5" onClick={this.handleChnage} disabled={additionalDisable} className={"btn btn-circle btn-value add-extra " + PLengthActive + ' ' + lengthRemoveHover} />
                    </div>
                  </div>
                </div>

                <h3 className={'bold-font pinareaslope-calc'}>PinAreaSlope<span>TM</span> (%)</h3>
                <div className="putt putt-box area D-putt clearfix">
                  <div className="paSlopeValue">
                    {/* <div className="value-add"> */}
                    <span>{this.state.formData.PASlope}</span>
                    <span className={'percentage'}>%</span>
                  </div>
                  <div className="select-value">
                    <div>
                      <input type="button" value={0} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 0) ? "active " : "")} />
                      <input type="button" value={0.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 0.5) ? "active " : "")} />
                      <input type="button" value={1} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 1) ? "active " : "")} />
                      <input type="button" value={1.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 1.5) ? "active " : "")} />
                      <input type="button" value={2} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 2) ? "active " : "")} />
                    </div>
                    <div>

                      <input type="button" value={2.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 2.5) ? "active " : "")} />
                      <input type="button" value={3} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 3) ? "active " : "")} />
                      <input type="button" value={3.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 3.5) ? "active " : "")} />
                      <input type="button" value={4} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 4) ? "active " : "")} />
                      <input type="button" value={4.5} data-action={"PASlope"} onClick={this.handleChnage} className={"btn btn-circle btn-value " + ((this.state.formData.PASlope === 4.5) ? "active " : "")} />
                    </div>
                  </div>
                </div>
                <h3 className={'bold-font greenspeed-calc'}>GreenSpeed<span></span> (Feet)</h3>
                <div className="putt putt-box area D-putt green clearfix">
                  <div className="value-add">
                    <span>{this.state.formData.GreenSpeed}'</span>
                  </div>
                  <div className="select-value">
                    <div>
                      <input type="button" data-state={7} data-action={"GreenSpeed"} onClick={this.handleChnage} value="7" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 7 && this.state.formData.GreenSpeed < 8) ? "active " : "")} />
                      <input type="button" data-state={8} data-action={"GreenSpeed"} onClick={this.handleChnage} value="8" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 8 && this.state.formData.GreenSpeed < 9) ? "active " : "")} />
                      <input type="button" data-state={9} data-action={"GreenSpeed"} onClick={this.handleChnage} value="9" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 9 && this.state.formData.GreenSpeed < 10) ? "active " : "")} />
                      <input type="button" data-state={10} data-action={"GreenSpeed"} onClick={this.handleChnage} value="10" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 10 && this.state.formData.GreenSpeed < 11) ? "active " : "")} />
                      <input type="button" data-state={11} data-action={"GreenSpeed"} onClick={this.handleChnage} value="11" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 11 && this.state.formData.GreenSpeed < 12) ? "active " : "")} />
                    </div>
                    <div>
                      <input type="button" data-state={12} data-action={"GreenSpeed"} onClick={this.handleChnage} value="12" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 12 && this.state.formData.GreenSpeed < 13) ? "active " : "")} />
                      <input type="button" data-state={13} data-action={"GreenSpeed"} onClick={this.handleChnage} value="13" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 13 && this.state.formData.GreenSpeed < 14) ? "active " : "")} />
                      <input type="button" data-state={14} data-action={"GreenSpeed"} onClick={this.handleChnage} value="14" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed >= 14 && this.state.formData.GreenSpeed < 15) ? "active " : "")} />
                      <input type="button" data-state={15} data-action={"GreenSpeed"} onClick={this.handleChnage} value="15" className={"btn btn-circle btn-value " + ((this.state.formData.GreenSpeed === 15) ? "active " : "")} />
                      <input type="button" data-state={"add"} data-action={"GreenSpeed"} onClick={this.handleChnage} value="+.5" disabled={additionalDisableGreen} className={"btn btn-circle btn-value add-extra " + GreenSpeedActive + ' ' + greenSpeedRemoveHover} />
                    </div>
                  </div>
                </div>
              </section>

            </div>
            <div className="clearfix"></div>
            <div className={'see-putt'}><a href={linkInit + "graph"} className="btn btn-primary">See My PuttPath!<sup>TM</sup></a></div>

          </div>

        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default GreenReading;