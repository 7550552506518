import React, { Component } from 'react';
import { linkInit } from '../../constants/config';
import { Redirect } from 'react-router-dom';
// import axios from 'axios';
import LOGO from '../../assets/img/pelz_golf_logo.gif';
import PROFILE from '../../assets/img/profile.png';
// import APPLE_STORE from '../../assets/img/apple_store.jpg';
// import GOOGLE_PLAY from '../../assets/img/google-play.jpg';
// import TOGGLE from '../../assets/img/toggle-icon.png';
// import BACK_BUTTON from '../../assets/img/back-icon.png';
// const menuList = { 'green-reading': 'Pelz BreakbookTM', 'graph': 'Graph', 'setting': 'Settings', 'get-app': 'Get App' }
class Header extends Component {
  constructor(props) {
    super(props);
    let isLoggedIn = false;
    let userName = '';
    if (localStorage.getItem('auth') !== 'null' && localStorage.getItem('auth') !== null) {
      isLoggedIn = true;
      userName = (JSON.parse(localStorage.getItem('auth')).user.email).match(/^([^@]*)@/)[1];
    }
    this.state = {
      loggedIn: isLoggedIn,
      userName: userName
    }
  }

  componentDidMount() {

    console.log(localStorage.getItem('auth'), "auth")
    if (document.querySelector('#mobile-header') && this.props.location.pathname === '/graph') {

      // document.querySelector('#mobile-header').style.display = 'none !important';
      document.querySelector('#mobile-header').style.display = 'none'
    }
  }

  componentDidUpdate() {
    console.log(localStorage.getItem('auth'), "auth")
    if (document.querySelector('#mobile-header') && this.props.location.pathname === '/graph') {

      // document.querySelector('#mobile-header').style.display = 'none !important';
      document.querySelector('#mobile-header').style.display = 'none'
    }
  }

  /**
   * @todo This method will handle logout functionality 
   * @return {null}
   */
  handleLogout = () => {
    localStorage.setItem('auth', null);
    this.setState({
      loggedIn: false
    });
  }

  /**
   * @todo This method will handle go back button click functionality 
   * @return {object}
   */

  goBack = () => {
    return window.history.back();
  };

  /**
   * @todo This method will handle header label based on the current route
   * @return {string}
   */

  conditionalHeaderTitle = () => {
    let title = <h3><a href={linkInit + "green-reading"} className={'header-title'}>BreakBook <sup>TM</sup>  by Pelz Golf</a></h3>;
    switch (this.props.location.pathname) {
      case '/graph':

        title = <h3><a href={linkInit + "graph"} className={'header-title '}>Pelz PuttPath<sup>TM</sup></a></h3>;
        break;
      case '/green-reading':

        title = <h3><a href={linkInit + "green-reading"} className={'header-title'}>Pelz BreakBook<sup>TM</sup></a></h3>;;
        break;

      case '/1':
        // title = <h3><a href={linkInit + "1"} className={'header-title'}>Pelz Tutorial</a></h3>;
        title = <h3><a href={linkInit + "1"} className={'header-title tutsHeader'}>BreakBook <sup>TM</sup>  by Pelz Golf </a></h3>;
        break;

      case '/':
        title = <h3><a href={linkInit} className={'header-title'}>BreakBook <sup>TM</sup>  by Pelz Golf</a></h3>;;
        break;
      case '/setting':
        title = <h3><a href={linkInit + "setting"} className={'header-title'}>Pelz Settings</a></h3>;;
        break;
      case '/get-app':
        title = <h3><a href={linkInit + "setting"} className={'header-title'}>Pelz Get Apps</a></h3>;;
        break;
      default:
        title = <h3><a href={linkInit + "1"} className={'header-title tutsHeader'}>BreakBook <sup>TM</sup>  by Pelz Golf </a></h3>;
        break;
    }
    return title
  }

  render() {

    let redirectUrl = this.props.location.pathname;
    // let pageTitle = (menuList[redirectUrl.substr(1)] != undefined) ? menuList[redirectUrl.substr(1)] : 'Home';

    console.log(this.state.loggedIn, "loggedin")


    // console.log(localStorage.getItem('auth'), "auth")

    if (!this.state.loggedIn && (redirectUrl === '/green-reading' || redirectUrl === '/graph' || redirectUrl === '/setting')) {
      return <Redirect to="/login" />
    }
    if (redirectUrl !== '/green-reading' && redirectUrl !== '/graph') {
      localStorage.setItem("formData", null);
    }

    return (
      <React.Fragment>
        <div className="desktop-view" id="desktop-header">
          <div className="D-header">
            <nav className="navbar navbar-default">
              <div className="container-fluid">
                <div className="navbar-header">
                  <a className="navbar-brand" href="/#">
                    <img src={LOGO} alt="" />
                  </a>
                </div>
                <ul id="D-menu" className="nav navbar-nav navbar-right">
                  <li className="GR-Active active"><a href={linkInit}><i className="fa fa-home" aria-hidden="true"></i> Pelz Golf Home</a></li>
                  <li className="GR-Active"><a href={linkInit + 'green-reading'}>
                    <i className="fa fa-university" aria-hidden="true"></i>BreakBook</a></li>
                  <li className="GR-Active"><a href={linkInit + 'graph'}><i className="fa fa-bar-chart" aria-hidden="true"></i> Graph</a></li>
                  <li className="GR-Active"><a href={linkInit + 'setting'}><i className="fa fa-cog" aria-hidden="true"></i> Settings</a></li>
                  <li className="GR-Active"><a href={linkInit + "get-app"}><i className="fa fa-cloud-download" aria-hidden="true"></i> Get App</a></li>
                  <li className="D-profile dropdown">
                    {(this.state.loggedIn === false) ?
                      <a href={linkInit + "login"} className="D-signup">LOG IN <i className="fa fa-sign-in" aria-hidden="true"></i></a>
                      :
                      <a className="dropdown-toggle" href="/#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={PROFILE} alt="" />
                        <span>{this.state.userName.charAt(0).toUpperCase() + this.state.userName.slice(1)}
                          <i className="caret"></i>
                        </span>
                      </a>
                    }
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button className="dropdown-item" onClick={() => this.handleLogout()}>Logout</button>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="D-heading">
            <div className="head-banner">
              <div className="container">
                <h2><i className="fa fa-home" aria-hidden="true"></i> Pelz Golf Home</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="tablet-mobile-view  header" id="mobile-header">
          <div className="container-fluid">
            <div className="row">
              {/* <div className="col-xs-2">
                <div className="back">
               
                  <img src={TOGGLE} className="toggle" alt="" />
                </div>
              </div> */}
              <div className="col-xs-12">
                <div className="heading text-center">

                  {this.conditionalHeaderTitle()}
                </div>
              </div>
              <div className="">
                <div className="profile">
                  <div className="profile-details">
                    {this.state.loggedIn && <img src={PROFILE} alt=""/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
