import React, { Component } from 'react';
import { linkInit } from '../../constants/config';
// import ReactPlayer from 'react-player';
// import LOGO from '../../assets/img/pelz_golf_logo.gif'
// import TOGGLE from '../../assets/img/toggle-icon.png'
// import PROFILE from '../../assets/img/profile.png';
// import BACK_BUTTON from '../../assets/img/back-icon.png';
import INTRO_VIDEO from '../../assets/video/DPIntroInstHomePage.MP4';
import GREADING_VIDEO from '../../assets/video/PelzTutorialVideo.TRIM.MP4';
// import $ from 'jquery';
// import { timingSafeEqual } from 'crypto';

class Section extends Component {
  constructor(props) {
    super(props);
    let isLoggedIn = false;
    let userName = '';
    if (localStorage.getItem('auth') !== 'null' && localStorage.getItem('auth') !== null) {
      isLoggedIn = true;
      userName = (JSON.parse(localStorage.getItem('auth')).user.email).match(/^([^@]*)@/)[1];
    }

    this.state = {
      loggedIn: isLoggedIn,
      userName: userName,
      videoClicked: false,
      introPage: true,
      videoPlaying: 1,
    }
  }


  componentDidMount() {
    var that = this;
    window.addEventListener('orientationchange', function () {
      console.log('window.location.pathname ', window.location.hash)
      if (window.innerHeight > window.innerWidth && (window.location.hash === '#/' || window.location.hash === '#/1')) {
        let video = null;
        if (that.state.videoPlaying === 1) {
          video = that.refs.introRef
        }
        else if (that.state.videoPlaying === 2) {
          video = that.refs.tutRef
        }
        if (video) {
          if (video.requestFullscreen) {
            video.requestFullscreen();
          } else if (video.msRequestFullscreen) {
            video.msRequestFullscreen();
          } else if (video.webkitRequestFullscreen) {
            video.webkitRequestFullscreen();
          }
        }

      }

    });
    this.refs.tutRef.onplay = function () {
      that.refs.introRef.pause();
      that.setState({
        videoPlaying: 2
      })
    };
    this.refs.introRef.onplay = function () {
      that.refs.tutRef.pause();
      that.setState({
        videoPlaying: 1
      })
    };
    console.log(this.state.loggedIn, "logged")

    if (this.props.location.pathname === '/') {
      this.setState({
        introPage: true
      })
    } else {
      this.setState({
        introPage: false
      })
    }
  }

  /**
     * @todo This method will handle logout functionality 
     * @return {object}
     */
  handleLogout = () => {
    localStorage.setItem('auth', null);
    this.setState({
      loggedIn: false
    }, () => {
      window.location.reload();
    });
  }

  componentDidUpdate() {
    if (this.props.location.pathname ==='/' && this.state.introPage === false) {
      this.setState({
        introPage: true
      })
      this.refs.introRef.play();
      this.refs.tutRef.pause();
    }

    if (this.props.location.pathname === '/1' && this.state.introPage === true) {
      this.setState({
        introPage: false
      })
      this.refs.tutRef.play();
      this.refs.introRef.pause();
    }

  }

  render() {
    return (
      <React.Fragment>
        <div className="main-container">
          <div className="intro-video">
            {/* <h3 className="text-center mt-2">Intro Video</h3> */}
            <div className="video">
              <video className="pelz-V" ref="introRef" src={INTRO_VIDEO} controls="controls" controlsList="nodownload" autoPlay={this.state.introPage}>     Your browser does not support the video tag.
		</video>
            </div>
          </div>

          <div className="user-welcome">
            <div className="container-fluid">
              <div className="row">
                <div className="user-login">
                  <div className="col-xs-6 p-r-none">
                    <h3>Welcome
                      {this.state.loggedIn ? <span>{this.state.userName.charAt(0).toUpperCase() + this.state.userName.slice(1)},</span> : <span>Guest,</span>}
                    </h3>
                  </div>
                  <div className="col-xs-6">
                    {this.state.loggedIn ?
                      <button className="btn btn-success btn-block G-btn" onClick={() => this.handleLogout()}>LOGOUT</button> :
                      <button className="btn btn-success btn-block G-btn"><a href={linkInit + "login"}>LOG IN</a></button>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="green-reading D-greenReading letsgo">
            <div className="container">
              <div className="row">
                <div className="read-putt">
                  <div className="col-xs-12">
                    <h3>
                      LET'S GO
                    <a href={linkInit + "green-reading"} className="btn btn-primary">READ MY PUTT !</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tutorial-video">
            {/* <h3 className="text-center">Dave Pelz - Green Reading 101</h3> */}
            {this.state.loggedIn === false && this.state.videoClicked ? <p style={{ textAlign: 'center' }} >Please login to view this Video</p> : null}
            <div className="video">
              <video onClick={() => {
                if (this.state.loggedIn === false) {
                  this.setState({
                    videoClicked: true
                  })
                }
              }} className="pelz-V" ref="tutRef" src={GREADING_VIDEO} autoPlay={this.state.loggedIn} controls={this.state.loggedIn ? true : false} controlsList="nodownload" >     Your browser does not support the video tag.
            </video>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Section;