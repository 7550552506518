import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import { Route, HashRouter } from 'react-router-dom';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactRouter from './routes';
// import $ from 'jquery';
import 'bootstrap3/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '../src/assets/css/custom.css';
import '../src/assets/css/mobile.css';
import 'popper.js/dist/popper.js';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

ReactDOM.render(
  <HashRouter>
    <Route component={ReactRouter} />
  </HashRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
