import React, { Component, Fragment } from "react";
import { API_URL } from "../../constants/config";
// import { linkInit, API_URL } from "../../constants/config";
// import LOGO from "../../assets/img/pelz_golf_logo.gif";
// import PROFILE from "../../assets/img/profile.png";
// import TOGGLE from "../../assets/img/toggle-icon.png";
// import BACK_BUTTON from "../../assets/img/back-icon.png";
import axios from "axios";
import './settings.css'

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playerSettingsId: "",
      playerPlayingStyle: "Righty",
      puttPower: "On",
      golfer: "On",
      tips: "Off",
      PelzLine: "Off",
      hasData: false,
      successMessage: ""
    };
  }

  componentDidMount() {
    let token = null;
    if (localStorage.getItem("auth") !== 'null' && localStorage.getItem("auth") !== null) {
      token = JSON.parse(localStorage.getItem("auth")).token
    }
    axios
      .get(`${API_URL}/private/playerSettings`,{
        headers: {
          Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }})
      .then(result => {
        result = result.data[0];
        this.setState({
          playerSettingsId: result.id,
          playerPlayingStyle: result.playerPlayingStyle,
          puttPower: result.PuttPower,
          golfer: result.Golfer,
          tips: result.Tips,
          PelzLine: result.PelzLine,
          hasData: true
        });
      })
      .catch(err => {
        console.log(err, "error");
      });
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log("submit");

    let token = null;
    if (localStorage.getItem("auth") !== 'null' && localStorage.getItem("auth") !== null) {
      token = JSON.parse(localStorage.getItem("auth")).token
    }

    const body = {
      id: this.state.playerSettingsId,
      playerPlayingStyle: this.state.playerPlayingStyle,
      PuttPower: this.state.puttPower,
      Golfer: this.state.golfer,
      Tips: this.state.tips,
      PelzLine: this.state.PelzLine
    };

    console.log("body==>", body);

    if (this.state.hasData) {
      axios
        .patch(
          `${API_URL}/private/playerSettings/`,
          body,
          {
            headers: {
              Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }}
        )
        .then(result => {
          console.log(result, "settigns result");
          this.setState({
            successMessage: "Settings has been saved successfully"
          });
        })
        .catch(err => {
          console.log(err, "settings error");
        });

      return;
    }

    axios
      .post(`${API_URL}/private/playerSettings`, body ,{
        headers: {
          Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }})
      .then(result => {
        console.log(result, "settigns result");
        this.setState({
          successMessage: "Settings has been saved successfully"
        });
      })
      .catch(err => {
        console.log(err, "settings error");
      });
  };

  handleRadio = e => {
    console.log(e.target.value, e.target.name, "radio");

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="main-container">
          <div className="setting">
            <div className="container">
              <div className="D-setting">
                {this.state.successMessage !== "" && (
                  <div className="alert alert-success alert-dismissible">
                    <a
                      href="/#"
                      className="close"
                      data-dismiss="alert"
                      aria-label="close"
                    >
                      &times;
                    </a>
                    {this.state.successMessage}
                  </div>
                )}

                <br />
                <form onSubmit={this.handleSubmit}>

                    <p className="setting-text-heading">Golfer</p>
                  <div className="golferContainer" >
                    <div>
                      <input
                        checked={this.state.golfer === "Off"}
                        value="Off"
                        onChange={this.handleRadio}
                        type="radio"
                        name="golfer"
                      />
                      <label className="setting-label-text">Off</label>
                      <input
                        checked={this.state.golfer === "On"}
                        value="On"
                        onChange={this.handleRadio}
                        type="radio"
                        name="golfer"
                      />
                      <label className="setting-label-text">On</label>
                    </div>

                    <div className="playingStyleContainer" >
                      {this.state.golfer === "On" && (
                        <Fragment>
                            <input
                            checked={
                                this.state.playerPlayingStyle === "Lefty"
                            }
                            value="Lefty"
                            onChange={this.handleRadio}
                            type="radio"
                            id="test2"
                            name="playerPlayingStyle"
                            />
                            <label className="setting-label-text" htmlFor="test2">LEFTY </label>
                 
                            <input
                              checked={
                                this.state.playerPlayingStyle === "Righty"
                              }
                              value="Righty"
                              onChange={this.handleRadio}
                              type="radio"
                              id="test1"
                              name="playerPlayingStyle"
                            />
                            <label className="setting-label-text" htmlFor="test1">RIGHTY</label>
                          
                   
                        </Fragment>
                      )}
                    </div>
                  </div>


                  <p className="setting-text-heading">
                    PuttPower<sup>TM</sup>
                  </p>
                  <input
                    checked={this.state.puttPower === "Off"}
                    value="Off"
                    onChange={this.handleRadio}
                    type="radio"
                    name="puttPower"
                  />
                  <label className="setting-label-text">Off</label>
                  <input
                    checked={this.state.puttPower === "On"}
                    value="On"
                    onChange={this.handleRadio}
                    type="radio"
                    name="puttPower"
                  />
                  <label className="setting-label-text">On</label>
                  
                  
                  <p className="setting-text-heading">Tips</p>
                  <input
                    checked={this.state.tips === "Off"}
                    value="Off"
                    onChange={this.handleRadio}
                    type="radio"
                    name="tips"
                  />
                  <label className="setting-label-text">Off</label>
                  <input
                    checked={this.state.tips === "On"}
                    value="On"
                    onChange={this.handleRadio}
                    type="radio"
                    name="tips"
                  />
                  <label className="setting-label-text">On</label>


                  <p className="setting-text-heading">Pelz Line</p>
                  <input
                    checked={this.state.PelzLine === "Off"}
                    value="Off"
                    onChange={this.handleRadio}
                    type="radio"
                    name="PelzLine"
                  />
                  <label className="setting-label-text">Off</label>
                  <input
                    checked={this.state.PelzLine === "On"}
                    value="On"
                    onChange={this.handleRadio}
                    type="radio"
                    name="PelzLine"
                  />
                  <label className="setting-label-text">On</label>


                  {/* <p className="setting-text-heading">PuttPath Animate</p>
                  <input
                    checked={this.state.animate === "Off"}
                    value="Off"
                    onChange={this.handleRadio}
                    type="radio"
                    name="animate"
                  />
                  <label className="setting-label-text">Off</label>
                  <input
                    checked={this.state.animate === "On"}
                    value="On"
                    onChange={this.handleRadio}
                    type="radio"
                    name="animate"
                  />
                  <label className="setting-label-text">On</label> */}
                  

                  <div className="text-right">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
