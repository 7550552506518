import React, { Component, Fragment } from "react";
import { API_URL } from "../../../constants/config";
// import { linkInit, API_URL } from "../../../constants/config";
// import { Redirect } from "react-router-dom";
// import Section from "../../layouts/Section";
// import LOGO from "../../../assets/img/pelz_golf_logo.gif";
import "./index.css";
import axios from "axios";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      requestEmail: "",
      successMessage: ""
      //loggedIn: localStorage.getItem('auth') !== null ? true : false
    };
    if (
      localStorage.getItem("auth") !== null &&
      localStorage.getItem("auth") !== "null"
    ) {
      this.props.history.push(`/1`);
    }
  }

  /**
   * @todo This method will handle all inputchanges and set state
   * @param {object} e
   * @return {null}
   */
  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  /**
   * @todo This method will handle form submission of request login and shoot mail
   * @param {object} e
   * @return {null}
   */
  handleSubmitRequest = e => {
    e.preventDefault();
    const body = {
      requestEmail: this.state.requestEmail
    };
    axios
      .post(`${API_URL}/public/requestEmail`, body)
      .then(result => {
        console.log(result, "result");
        this.setState({
          successMessage:
            "Your registration request has been sent successfully. Please check your email"
        });
      })
      .catch(err => {
        console.log(err, "err");
      });
  };

  /**
   * @todo This method will handle form submission of login
   * @param {object} e
   * @return {null}
   */
  handleSubmit = async e => {
    e.preventDefault();
    axios
      .post(`${API_URL}/public/login`, {
        email: this.state.email,
        password: this.state.password
      })
      .then(result => {
        if (result.status === 200) {
          // this.props.history.push(`/`);
          localStorage.setItem("auth", JSON.stringify(result.data));
          this.setState({
            ...this.state,
            errorMessage: ""
          });
          // window.location = "#/1";
          window.location.reload()
        } else {
          this.setState({
            ...this.state,
            errorMessage: "Invalid Username & Password! Please try again or request down below."
          });
        }
      })
      .catch(err => {
        this.setState({
          ...this.state,
          errorMessage: "Invalid Username & Password! Please try again or request down below."
        });
        console.log(err, "err");
      });
  };
  render() {
    // console.log("Login History==>", this.props);
    return (
      <Fragment>
        {this.state.successMessage !== "" && (
          <div class="alert alert-success alert-dismissible">
            <a href="/#" class="close" data-dismiss="alert" aria-label="close">
              &times;
            </a>
            {this.state.successMessage}
          </div>
        )}
        <div className="loginFormContainer">
          <div className="loginBox">
            {this.state.errorMessage !== "" && (
              <div class="alert alert-danger alert-dismissible">
                <a
                  href="/#"
                  class="close"
                  data-dismiss="alert"
                  aria-label="close"
                >
                  &times;
                </a>
                {this.state.errorMessage}
              </div>
            )}
            <h2 style={{ fontWeight: "700", marginBottom: '8px' }}> Pelz BreakBook<sup>TM</sup>  Login </h2>

            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                <input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter your login email address"
                  required
                />
                <small id="emailHelp" className="form-text text-muted" />
              </div>
              <div className="form-group">
                <label
                  htmlFor="exampleInputPassword1"
                  style={{ fontWeight: "700" }}
                >
                  Password
                </label>
                <input
                  name="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter your password"
                  required
                />
              </div>

              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg G-btn loginButtons"
                  style={{ fontWeight: "600" }}
                >
                  Submit
                </button>
              </div>
            </form>

            <h2 className="mt-2" style={{ fontWeight: "700", marginBottom: '8px' }}>
              {" "}
              I'd like to try the Pelz BreakBook<sup>TM</sup> {" "}
            </h2>

            <form onSubmit={this.handleSubmitRequest}>
              <div className="form-group">
                {/* <label htmlFor="exampleInputEmail1">Request Email</label> */}
                <input
                  name="requestEmail"
                  value={this.state.requestEmail}
                  onChange={this.handleInputChange}
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter your email to request a password"
                  required
                />
                <small id="emailHelp" className="form-text text-muted" />
              </div>

              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg G-btn loginButtons"
                  style={{ fontWeight: "600" }}
                >
                  Request
                </button>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}
