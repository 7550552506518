import React, { Component } from 'react';
// import Header from './../layouts/Header';
import Section from './../layouts/Section';
// import Footer from './../layouts/Footer';

class Common extends Component {
  // constructor(props){
  //   super(props);
  // }
  render() {
    return (
      <Section location={this.props.location} />
      // <div className="App">
      //   <Header />
      //   <Section />
      //   <Footer />
      // </div>
    );
  }
}

export default Common;
