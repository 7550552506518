import React, { Component, Fragment } from 'react';
// import { linkInit, API_URL } from '../../../constants/config';
import {  API_URL } from '../../../constants/config';
// import {Redirect} from 'react-router-dom';
// import Section from '../../layouts/Section'
// import LOGO from '../../../assets/img/pelz_golf_logo.gif';
import './index.css';
import axios from 'axios';

export default class Register extends Component {

    constructor(props){
        super(props);

        this.state ={
            email : '',
            password : '',
            password2: '',
            errorMessage: '',
            valid : false
            //loggedIn: localStorage.getItem('auth') !== null ? true : false
        }
    }

    componentWillMount(){
        console.log("hello")
         console.log(this.props.match.params.hash, "this.props") 


         axios.post(`${API_URL}/public/validloginrequest/${this.props.match.params.hash}`)
            .then(result => {
                console.log(result, "result")

                this.setState({
                    valid : true
                })
            })
            .catch(err => {
                console.log(err, "err ")
            })

    }

      /**
   * @todo This method will handle all inputchanges and set state
   * @param {object} e
   * @return {null}
   */
    handleInputChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    } 

 /**
   * @todo This method will handle form submission and set state
   * @param {object} e
   * @return {null}
   */
    handleSubmit = async (e) => {
        e.preventDefault();
        axios.post(`${API_URL}/public/register`, {
            "email":this.state.email,
            "password": this.state.password,
            "password2": this.state.password2
        }).then((result) => {
                if(result.status === 200){
                    this.props.history.push(`/`);
                    localStorage.setItem('auth',JSON.stringify(result.data));
                    this.setState({
                        ...this.state,
                        errorMessage: ''
                    });
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: 'Something Wrong !!! Invalid Username & Password'
                    });
                }
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    errorMessage: 'Something Wrong !!! Invalid Username & Password'
                });
                console.log(err, "err");
            })

    }
    render(){
        // console.log("Login History==>", this.props);
        return (
            <Fragment>

                {this.state.valid ? <div className="loginFormContainer" >
                 <div className="loginBox" >
                 {this.state.errorMessage !== '' && <h2 style={{color:'red'}}>{this.state.errorMessage}</h2>}
                 <h2 style={{fontWeight:"700"}} > Register </h2>

                    <br />
                 <form onSubmit={this.handleSubmit} > 
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input name="email" value={this.state.email} onChange={this.handleInputChange} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" required />
                        <small id="emailHelp" className="form-text text-muted"></small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input name="password" value={this.state.password} onChange={this.handleInputChange} type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Confirm Password</label>
                        <input name="password2" value={this.state.password2} onChange={this.handleInputChange} type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" required />
                    </div>
                   
                    <div className="text-right">
                        <button type="submit" className="btn btn-primary btn-lg">Submit</button>
                    </div>

                    
                    </form>

                
                    
                 </div>  
              </div> : <h1>You are not the valid user.</h1>}
              
                
            </Fragment>
        )
    }
}