import React, { Component } from 'react';
import { linkInit } from '../../constants/config';
class Footer extends Component {
  // constructor(props){
  //   super(props);
  // }

  componentDidMount() {
    this.activateTabs()
  }


  /**
     * @todo This method will handle highlighting of the buttom buttons when navigated to the route 
     * @return {object}
     */

  activateTabs() {

    let elem = document.querySelectorAll('.menu-btn');
    let index = 0;

    switch (this.props.location.pathname) {
      case '/1':
        index = 0;
        break;
      case '/green-reading':
        index = 1;
        break;
      case '/graph':
        index = 2;
        break;
      default:
        break;
    }

    elem.forEach(item => {
      item.children[0].children[0].style.color = 'grey';
      item.children[0].children[1].style.color = 'grey';
      item.children[0].children[1].style.fontWeight = '400';
    })

    elem[index].children[0].children[0].style.color = '#5f8e00';
    elem[index].children[0].children[0].style.fontWeight = '600';
    elem[index].children[0].children[1].style.color = '#5f8e00';
    elem[index].children[0].children[1].style.fontWeight = '600';



  }




  componentDidUpdate() {
    this.activateTabs()
  }


  // renderLocationBasedRoute = () => {
  //   if(this.props.location.pathname == '/green-reading'){
  //     return (

  //       <li className="menu-btn">
  //       <a href={linkInit + "graph"}>
  //         <i className="fa fa-bar-chart" aria-hidden="true"></i>
  //         <h3>PuttPath</h3>
  //       </a>
  //     </li>
  //     )
  //   } else {
  //     return (

  //       <li className="menu-btn">
  //       <a href={linkInit + "green-reading"}>
  //         <i className="fas fa-clock" aria-hidden="true"></i>
  //         <h3>BreakBook</h3>
  //       </a>
  //     </li>

  //     )
  //   }
  // }



  // activateCurrentTab = (e) => {


  //   // document.querySelectorAll('.menu-btn').map(item => {
  //   //   // item.children[0].children[0].style.color = 'grey';
  //   //   // item.children[0].children[0].style.color = 'grey';
  //   //   console.log(item, "item");
  //   // })

  //   document.querySelectorAll('.menu-btn').forEach(item => {

  //       item.children[0].children[0].style.color = 'grey';
  //     item.children[0].children[1].style.color = 'grey';
  //     item.children[0].children[1].style.fontWeight = '400';
  //     // console.log(item, "item");
  //   })


  //   // [1,3,4].map(item => {
  //   //   console.log(item)
  //   // })

  //   // console.log(document.querySelectorAll('.menu-btn')[0].children[0].children[0], "queryall")

  //   e.currentTarget.children[0].children[0].style.color = '#5f8e00';
  //   e.currentTarget.children[0].children[0].style.fontWeight = '600';
  //   e.currentTarget.children[0].children[1].style.color = '#5f8e00';
  //   e.currentTarget.children[0].children[1].style.fontWeight = '600';
  // }


  render() {
    return (
      <React.Fragment>
        <div className="footer" id="footer">
          <div className="bottom-footer">
            <ul id="menu">
              {/* <li className="menu-btn">
                <a href={linkInit + "green-reading"}>
                  <i className="fa fa-university" aria-hidden="true"></i>
                  <h3>GReading</h3>
                </a>
              </li> */}
              {/* <li className="menu-btn">
                <a href={linkInit}>
                  <i className="fa fa-university" aria-hidden="true"></i>
                  <h3>Intro</h3>
                </a>
              </li> */}

              <li className="menu-btn">
                <a href={linkInit + '1'}>
                  <i className="fas fa-video" aria-hidden="true"></i>
                  <h3>Tutorial</h3>
                </a>
              </li>

              <li className="menu-btn">
                <a href={linkInit + "green-reading"}>
                  <i className="fas fa-clock" aria-hidden="true"></i>
                  <h3>BreakBook<sup>TM</sup></h3>
                </a>
              </li>


              <li className="menu-btn">
                <a href={linkInit + "graph"}>
                  <i class="fa fa-line-chart" aria-hidden="true"></i>
                  {/* <i className="fa fa-bar-chart" aria-hidden="true"></i> */}
                  <h3>PuttPath<sup>TM</sup></h3>
                </a>
              </li>


              {/* {this.renderLocationBasedRoute()} */}





              {/* <li className="menu-btn active">
                <a href={linkInit + "setting"}><i className="fa fa-cog" aria-hidden="true"></i>
                  <h3>Settings</h3>
                </a>
              </li>
              <li className="menu-btn">
                <a href={linkInit + "get-app"}>
                  <i className="fa fa-cloud-download" aria-hidden="true"></i>
                  <h3>Get Apps</h3>
                </a>

              </li> */}
              <li className="menu-btn">
                <a href="http://www.pelzgolf.com/" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <h3>Pelz Golf</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="D-footer desktop-view" id="desktop-footer">
          <div className="container">
            <div className="row">
              <h3 className="copyrighht">Copyright © 1998-2019</h3>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;